import './css/CustomSnackbar.css'

import React, { useState, useEffect, useContext } from 'react';

import { SnackbarModel  } from '../models'


import Slide, { SlideProps } from '@mui/material/Slide';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import Datetime from '../helpers/datetime';


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


type TransitionProps = Omit<SlideProps, 'direction'>;
function TransitionRight(props: TransitionProps) {
    return <Slide {...props} direction="left" />;
  }

const CustomSnackbar = (props:SnackbarModel) => {
    
    const [isOpened, setIsOpened] = useState<boolean>(true);
    
    const handleClose = (e:any, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        
        setIsOpened(false)
    }

    const millis = (props.millis || 2000) + (225);
    if(!props.persistent){
        setTimeout(() => {
            if(isOpened){
                setIsOpened(false)
            }
        }, millis);
    }

    
    const timestamp = new Datetime().getUnixTimestampMillis();
    const exp_timestamp = ( props.exp_timestamp || timestamp ) + millis; //225 transition time

    const data = props.data || null;
    


    useEffect(() => {
        if(isOpened && props.onShow){
            props.onShow();
        }
        if(!isOpened && props.onHide){
            props.onHide();
        }
    }, [isOpened, props]);

    return (
        <>
        {
            // timestamp < exp_timestamp ? 
                <Snackbar
                    open={isOpened}
                    anchorOrigin={{ vertical: props.vertical || 'bottom', horizontal: props.horizontal || 'right' }}
                    autoHideDuration={!props.persistent ? millis : null} onClose={handleClose}
                    TransitionComponent={TransitionRight}
                    style={{position: 'relative'}}
                    onClick={
                        (e:any) => {
                            if(props.onClick){
                                props.onClick(data, e);
                            }
                            if(props.closeOnClick){
                                setIsOpened(false)
                            }
                        }
                    }
                >
                    <Alert onClose={ !props.hideCloseTimes ? handleClose : undefined} severity={props.severity || 'info'} sx={{ width: '100%' }}>
                        {props.message}
                    </Alert>
                </Snackbar>
            // :
            //     <></>
        }
        </>
    );
}

export default CustomSnackbar;


// {
//     message:<button onClick={() => {
//         snackbar.hide(snackbar.data.length);
//     }}>
//                 'You did not edit the employee!'
//             </button>,
//     severity: 'info',
//     closeOnClick: false,
//     hideCloseTimes: true,
//     data:{
//         firstname: 'test'
//     },
//     persistent: true
// };