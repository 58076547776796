import './css/CustomButton.css';


import React from 'react';

import { Button, Tooltip } from '@mui/material';


function CustomButton (props: any) {
    // console.log(props)


    let className = "btn ";
    switch(props.variant){
        case "primary": {
            className += 'btn-primary';
            break;
        }
        case "secondary": {
            className += 'btn-secondary';
            break;
        }
        default: {
            className += 'btn-primary';
            break;
        }
    }
    
    if(props.class){
        className = className + ' ' + props.class;
    }


    // console.log( props )
    let childrens = null;
    if( props.children && Array.isArray(props.children) ){
        childrens = props.children.map((component: any, i: number) => component)
    }else{
        childrens = props.children
    }

    return (
        <Tooltip placement={props.placement} title={props.title}>
            <button className={className} onClick={ () => {
            if( props.onClick ){
                props.onClick() 
            }else{
                console.log('onClick')
            }
            }
            } 
            type={props.type ? props.type : 'button'}>
            {
                childrens   // props.children && props.children.map((component: any, i: number) => component)
            }
            </button>
        </Tooltip>
    );
}

export default React.memo(CustomButton);