import './css/Card.css';


import React from 'react';

import { Box } from '@mui/material';
import Title from './Title'


function Card (props: any) {
    
    let className = "card ";
    switch(props.type){
        case "floating": {
            className = 'floating-card';
            break;
        }
    }

    if(props.class){
        className = className + ' ' + props.class;
    }


    // console.log(props)
    let childrens = null;
    if( props.children && Array.isArray(props.children) ){
        childrens = props.children.map((component: any, i: number) => component)
    }else{
        childrens = props.children
    }
    // console.log(childrens)
    const {title, actions} = props;

    return (
        <Box className={className} onDoubleClick={props.onDoubleClick}>
            { (title || actions) &&
                <div className='cardHeader'>
                    <div className='cardHeaderTitle'>
                        <Title>{title || ''}</Title>
                    </div>
                    <div className='cardHeaderActions'>
                        {actions || <></>}
                    </div>
                </div>
            }
            {
                childrens
            }
        {props.bubbles &&
            <>
                <div className="bubble small"></div>
                <div className="bubble s-medium"></div>
                <div className="bubble medium"></div>
                <div className="bubble large"></div>
                <div className="bubble small-l"></div>
                <div className="bubble small-2"></div>
            </>
        }
        </Box>
    );
}

export default React.memo(Card);