import { components } from 'react-select';

const { Control } = components;

export const CustomSelectControl = (props:any) => {
    return (
        <div onClick={props.selectProps.onControlClick}>
            <Control {...props}/>
        </div>
    );
}