import './css/CustomSnackbarContainer.css';

import React, { useState, useRef, useMemo } from 'react';

import { ctxSnackbar } from '../store';

import CustomSnackbar from '../components/CustomSnackbar';

import Datetime from '../helpers/datetime';

import { SnackbarModel  } from '../models'

const CustomSnackbarContainer = (props:any) => {
    
    const [snack, setSnack] = useState<SnackbarModel[]>([]);//useState<SnackbarModel[] | null>([]);
    const snackRef = useRef<SnackbarModel[]>();
    snackRef.current = snack;

    let childrens = null;
    if( props.children && Array.isArray(props.children) ){
        childrens = props.children.map((component: any, i: number) => component)
    }else{
        childrens = props.children
    }
    
    const setter = (value:SnackbarModel) => {
        const timestamp = new Datetime().getUnixTimestampMillis();
        
        value.exp_timestamp = timestamp + (value.millis ? value.millis : 2000);

        const temp = [
            ...snack,
            value
        ];
        setSnack([ ...temp ]);

        return temp.length-1;
    }

    const hide = (index:number) => {

        // console.log(index);
        
        if(snackRef.current && snackRef.current[index]){
            const temp = [...snackRef.current];
            

            temp[index].persistent = false;
            temp[index].millis = 1;
            setSnack([ ...temp ]);
        }
    }
    

    const snackbarContainer = useMemo( () => {
        return (
            <div id='snackbarContainer'>
                { 
                snack.map((s:any, i:number) => {
                    // const timestamp = new Datetime().getUnixTimestampMillis();
                    return (
                        <CustomSnackbar key={i} {...s} />
                        );
                    // if( timestamp < s.exp_timestamp ){
                    //     return (
                    //     <CustomSnackbar key={i} {...s} />
                    //     );
                    // }else{
                    //     return (<></>);
                    // }
                })
                }
            </div>
        )
    }, [snack])

    return (
        <>
        <ctxSnackbar.Provider value={ {data: snack, set: setter, hide} }>
        {
            childrens
        }
        </ctxSnackbar.Provider>
        {snackbarContainer}
        </>
    );
}

export default CustomSnackbarContainer;