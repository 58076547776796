import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
  Customized,
  Rectangle,
} from 'recharts';
import iconClose from '../../assets/images/icons/close.svg';
import iconCancel from '../../assets/images/icons/cancel.svg';
import iconArrowUp from '../../assets/images/icons/arrowUp.svg';
import iconArrowDown from '../../assets/images/icons/arrowDown.svg';
import { debounce } from 'lodash';


/** --PROPS-- */


/** @summary --CHART ELEMENT PROPS-- */

type ChartData = {
    name: string;
    max: number;
    min: number;
    start: number;
    end: number;
};

type ChartMetaData = {
    max: number;
    min: number;
    avg: number;
    isLive: boolean;
    isDrillDown: boolean;
}

type PatientAlerts = {
    temp: boolean,
    BPM: boolean,
    spo2: boolean,
    dia: boolean,
    sys: boolean
}


/** @summary --CUSTOM BARCHART PROPS-- */

type Props = {
    data: ChartData[];
    color1: string;
    color2: string;
    barColor: string;
    index: number;
    onClose: (type: string) => void;
    onRectangleClick: (startIndex: string, endIndex:string, measurementType:string) => void;
    onUndoClick: (chartType: string) => void;
    onReorderClick: (chartType: string, direction: boolean) => void;
    updateBrushState?: (chartType: string, newBrushIndex: number[]) => void;
    show: boolean;
    name: string;
    metadata: ChartMetaData;
    alerts: PatientAlerts | undefined;
    newBrushState?: number[];
};

// Custom Debug hook
function usePrevious(value:any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}



/** --COMPONENTS-- */



/** RECTANGLE CHILD COMPONENT */

const CustomizedRectangle = (props: any) => {

    /** @section Registers Initialization */

    const { formattedGraphicalItems, barColor, handleClick, name, metadata } = props;
    const firstSeries = formattedGraphicalItems[0];
    const secondSeries = formattedGraphicalItems[1];
    const [hoverIndex, setHoverIndex] = useState(null);


    /** @section --CONDITIONAL RENDERING-- */

    // Render custom content using points from the graph
    return firstSeries?.props?.points.map((firstSeriesPoint:any, index:any) => {
        const secondSeriesPoint = secondSeries?.props?.points[index];
        const yDifference = (firstSeriesPoint.y - secondSeriesPoint.y) || 0;


        return (
            <g 
                key={firstSeriesPoint.payload.name} 
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(null)}
            >

                <Rectangle
                    width={metadata.isDrillDown ? 20 : 15}
                    height={yDifference}
                    x={metadata.isDrillDown ? secondSeriesPoint.x - 10 : secondSeriesPoint.x - 7.5}
                    y={secondSeriesPoint.y}
                    fill={metadata.isDrillDown ? 'transparent' : barColor}
                    // onClick={() => {handleClick(firstSeriesPoint.payload.start, firstSeriesPoint.payload.end, name); console.log(metadata)}}
                    stroke={hoverIndex === index ? (metadata.isDrillDown? 'transparent' : 'white') : 'transparent'}
                    strokeWidth={hoverIndex === index ? (metadata.isDrillDown? 0 : 2) : 0}
                />

                {!metadata.isDrillDown &&
                    <rect
                        x={secondSeriesPoint.x - 20}
                        y={firstSeriesPoint.y - 5}
                        width={40}
                        height={Math.abs(yDifference) + 10}
                        fill="transparent"
                        onClick={() => { handleClick(firstSeriesPoint.payload.start, firstSeriesPoint.payload.end, name); console.log(metadata) }} 
                    />
                }
                
                <text
                    x={secondSeriesPoint.x + 20}
                    y={secondSeriesPoint.y + 12}
                    textAnchor="middle"
                    fill="#ccc"
                    fontSize={metadata.isDrillDown? 0 : 10}
                >
                {secondSeriesPoint.value.toFixed(2)}
                </text>

                {/* UPPER LABEL */}
                <text
                    x={metadata.isDrillDown? secondSeriesPoint.x + 15 : secondSeriesPoint.x + 20}
                    y={firstSeriesPoint.y - 5}
                    textAnchor="middle" 
                    fill={(metadata.isDrillDown && hoverIndex === index) || (metadata.isLive && index === firstSeries?.props?.points.length - 1) ? "#ccc" : (metadata.isDrillDown ? "transparent" : "#ccc")}
                    fontSize={metadata.isDrillDown? 15 : 10}
                >
                {firstSeriesPoint.value.toFixed(2)}
                </text>

                {metadata.isDrillDown && hoverIndex === index &&
                    <circle
                        cx={secondSeriesPoint.x}
                        cy={firstSeriesPoint.y}
                        r={2}
                        fill="#ccc"
                    />
                }

                {metadata.isLive && index === firstSeries?.props?.points.length - 1 &&
                    <circle
                        cx={secondSeriesPoint.x}
                        cy={firstSeriesPoint.y}
                        r={2}
                        fill="#ccc"
                        stroke={barColor} 
                        strokeWidth="2" 
                    />
                }

            </g>
        )
    })
};



/** BARCHART PARENT COMPONENT */

const CustomBarChart: React.FC<Props> = ({ data, color1, color2, barColor, index, onClose, show, name, metadata, onRectangleClick, onUndoClick, onReorderClick, alerts, updateBrushState, newBrushState }) => {

    /** @section Registers Initialization */

    const [zoomedData, setZoomedData] = useState(data);         // Zoom register
    const [width, setWidth] = useState(window.innerWidth);      // ResponsiveContainer register
    const [opacity, setOpacity] = useState(1);                  // Chart opacity register
    const [undoClicked, setUndoClicked] = useState(false);      // Undo click state
    const [brushRange, setBrushRange] = useState([0, 5]);       // Initial range for 6 data points
    const [prevBrushIndex, setPrevBrushIndex] = useState([0, data.length - 1]);
    const updateBrushStateDebounced = debounce((chartType, newBrushIndex) => {
        updateBrushState && updateBrushState(chartType, newBrushIndex);
    }, 500);


    // LivePanning register
    const [userHasPanned, setUserHasPanned] = useState(false);  
    const [userPanning, setUserPanning] = useState([0,0]);
    const [userZoom, setUserZoom] = useState(data);

    /** @section Rendering Debug */

    // useEffect(() => {
    //     console.log("Alerts: ",alerts)
    // }, [data, alerts])

    // // Initialize props debug
    // const props = { data, color1, color2, barColor, index, onClose, show, name, metadata, onRectangleClick, onUndoClick, onReorderClick }

    // // Creating an array of dependencies
    // const prevProps = usePrevious(props);

    // useEffect(() => {
    //     Object.entries(props).forEach(([key, value]) => {
    //         if (prevProps && value !== prevProps[key]) {
    //             console.log(`Prop '${key}' changed from`, prevProps[key], 'to', value);
    //         }
    //     });
    // }, [props]); // This will only re-run if props object reference changes


    /** @section Resizing */

    // Listen for window resize events and update ResponsiveContainer width
    useEffect(() => {

        const handleResize = () => {
            setWidth(window.innerWidth);
        }
    
        window.addEventListener('resize', handleResize);
        
        // Cleanup function to remove event listener
        return () => {
        window.removeEventListener('resize', handleResize);
        }

    }, []);

    // Calculate width in vw
    const vwToPx = (value: number) => {
        return value * width / 145;
    }


    /** @section Zooming */

    // Zoom chart
    useEffect(() => {
        setZoomedData(data);
    }, [data]);


    /** @section DrillDown Undo */

    // onUndoClick: Cool fading effect
    useEffect(() => {
        let timer1: number;
        let timer2: number;

        if (undoClicked) {
            setOpacity(0);
            timer1 = window.setTimeout(() => {
                onUndoClick(name);
                timer2 = window.setTimeout(() => {
                    setOpacity(1);
                    setUndoClicked(false);
                }, 1000);
            }, 500);
        }

        return () => {
            window.clearTimeout(timer1);
            window.clearTimeout(timer2);
        };
    }, [undoClicked]);


    /** @section Live Shift */

    // Set the chart window to display the last 12 data points (can be parametrized)
    useEffect(() => {
        if ( metadata.isLive && data.length >= 12) {
            if (userHasPanned) {
                setBrushRange(userPanning);
                setZoomedData(userZoom); // Apply userZoom here
            } else {
                setBrushRange([data.length - 12, data.length - 1]);
            }
        }
    }, [data, metadata.isLive, userHasPanned]);

    // Detect if the user is panning in order not to overlap the user's actions during updates (tolerance can be parametrized)
    const handleBrushChange = (brushState:any) => {
        const { startIndex, endIndex } = brushState;

        // If brush is at end, user is not manually controlling
        if(data.length - endIndex <= 3) { // Adjust number to increase tolerance
            setUserHasPanned(false);
        } else {
            setUserHasPanned(true);
            setUserPanning([startIndex, endIndex]);
            setUserZoom(data);
        }
    }

    useEffect(() => {
        if (newBrushState && newBrushState.length === 2) {
            setPrevBrushIndex(newBrushState);
        }
    }, [newBrushState]);
    


    /** @section Component Styles */

    const buttonStyle = {
        position: 'absolute' as const,
        top: '0',
        right: '0',
        background: 'none',
        border: 'none',
        color: 'white',
    };

    const indexButtonStyle = {
        display: 'flex', 
        justifyContent:'center', 
        alignItems:'start', 
        background:'transparent',
        border: 'none',
    }
    
    const styles = {
        buttonCloseChart: {
            ...buttonStyle,
            margin: '0.5em 0.5em 0 0',
        },
        buttonUndoDrillDown: {
            ...buttonStyle,
            margin: '0.5em 1.5em 0 0',
        },
        labelMetaData : {
            display: 'flex',
            justifyContent: 'space-between',
            color:'white',
            fontSize: '0.8em',
            margin: '0 0 0 0' 
        },
        chartContainer: {
            position: 'relative' as const,
            height: 205,
            width: '100%',
            display: 'flex',
            justifyContent:'space-between', 
            opacity, transition: 'opacity 0.5s'
        },
        infoBox: {
            position:'relative' as const, 
            borderRadius: '5px', 
            width:'22.5%',
            height: '95%', 
            backgroundColor:'var(--gray-700)', 
            padding:'1em'
        },
        indexBox: {
            position: 'relative' as const,
            borderRadius: '5px',
            width:'2%',
            height: '20%',
            backgroundColor: 'var(--gray-700)',
            display: 'flex',
            flexDirection: 'column' as const,
            justifyContent: 'space-between',
            zIndex: 400,
            marginLeft: '0.25em',
            marginTop: '4.75em'
        }
    };


    /** @section Conditional Rendering */

    if (!show) {
        return null;
    }

    return (

        /* CHART CONTAINER */
        <div style={styles.chartContainer}>
            <div style={{ display: 'flex', justifyContent:'center', alignItems:'center', width:'75%'}}
                onMouseUp={() => {
                    updateBrushState && updateBrushState(name, prevBrushIndex);

                }}
            >
                {
                    data.length === 0
                    ?
                        <h3 style={{color:'white'}}> No data to be displayed</h3>
                    :
                    <ResponsiveContainer width={vwToPx(50)} >
                        <LineChart data={zoomedData} width={500} height={200} margin={{top: 20, right: 75, left: 0, bottom: 35}}>
                            <CartesianGrid strokeDasharray="3 3" stroke={'gray'}/>
                            <XAxis 
                                dataKey={(d) => {
                                    if (metadata.isDrillDown) {
                                        const parts = d.name.split(',');
                                        return parts[1].trim(); 
                                    }
                                    return d.name;
                                }} 
                                angle={25} 
                                textAnchor="start" 
                                interval={0} 
                                fontSize={'0.5em'} 
                                stroke={'#ccc'} 
                            />   
                            <YAxis domain={['auto', (metadata.isDrillDown && !metadata.isLive) ? metadata.max * 1.5 : 'auto']} />
                            <Line type="monotone" dataKey="max" stroke={color1} isAnimationActive={false} dot={false} />
                            <Line type="monotone" dataKey="min" stroke={metadata.isDrillDown ? "transparent" : color2} isAnimationActive={false} dot={false} />
                            {!metadata.isLive && data.length > 4
                            &&
                            // <Brush 
                            //     dataKey="name"
                            //     height={20}
                            //     stroke="var(--indigo-dye)"
                            //     y={170}
                            //     startIndex={brushRange[0]}
                            //     endIndex={brushRange[1]}
                            //     onChange={handleBrushChange}
                            // />
                            //:
                            <Brush 
                                dataKey="name"
                                height={20}
                                stroke="var(--indigo-dye)"
                                y={170}
                                startIndex={prevBrushIndex[0]}
                                endIndex={prevBrushIndex[1]}     
                                onChange={(brushState) => {
                                    let startIndex, endIndex;

                                    if (newBrushState) {
                                        [startIndex, endIndex] = newBrushState;
                                    } else {
                                        startIndex = brushState.startIndex ?? 0;
                                        endIndex = brushState.endIndex ?? data.length - 1;
                                    }
                                
                                    setPrevBrushIndex([startIndex, endIndex]);
                                }}
                                
                            />
                            }
                            <Customized component={CustomizedRectangle} barColor={barColor} handleClick={!metadata.isDrillDown ? onRectangleClick : {}} name={name} metadata={metadata}/>
                        </LineChart>
                    </ResponsiveContainer>
                }
            </div>




            {/* INFO BOX */}
            <div style={styles.infoBox}>


                {/* SIDE BUTTONS */}
                <button onClick={() => onClose(name)} style={styles.buttonCloseChart}>
                    <img src={iconClose} style={{ width: '10px', height: '10px', cursor: 'pointer', zIndex: 500 }} />
                </button>

                {!metadata.isLive && 
                <button onClick={() => setUndoClicked(true)} style={styles.buttonUndoDrillDown}>
                    <img src={iconCancel} style={{ width: '10px', height: '10px', cursor: 'pointer', zIndex: 500 }} />
                </button>
                }   


                {/* METADATA */}
                <div style={{display: 'flex', height:'100%', flexDirection:'column', justifyContent: 'space-between'}}>

                    <div>

                        <h5 style={{color:'var(--green-1)'}}>{name}</h5>
                        <h4 style={{color:'black', fontSize: '0.8em', marginBottom:'0.35em'}}>
                            {data[0]?.name && 
                            `${data[0]?.name.split(',')[0]}/${new Date().getFullYear()}, ${data[0]?.name.split(',')[1]}`}
                        </h4>   

                        <div style={{width:'75%'}}> 

                        <div style={styles.labelMetaData}>
                            {Boolean(metadata.max) &&<span>Max.:</span>}
                            {Boolean(metadata.max) && <strong>{metadata.max.toFixed(2)}</strong>}
                        </div>

                        <div style={styles.labelMetaData}>
                            {Boolean(metadata.max) &&<span>Min.:</span>}
                            {Boolean(metadata.max) && <strong>{metadata.min.toFixed(2)}</strong>}
                        </div>

                        <div style={styles.labelMetaData}>
                            {Boolean(metadata.max) &&<span>Avg.:</span>}
                            {Boolean(metadata.max) && <strong>{metadata.avg.toFixed(2)}</strong>}
                        </div>

                        </div>

                    </div>

                    <div style={{height:'30%'}}>
                    <h3 style={{
                        color: alerts !== undefined && alerts[(name === "BPM" ? "BPM" : name.toLowerCase()) as keyof PatientAlerts] ? 'var(--sizzling-red)' : 'var(--green-1)',
                        fontSize:'2em'
                    }}>
                        {name}
                    </h3>                 
                    
                    </div>

                </div>


            </div>



            {/* INDEX ORDER CONTAINER */}
            <div  style={{...styles.indexBox,  background:'transparent'}}>

                {/* INDEX ORDER ARROWS */}
                
                <button onClick={() => onReorderClick(name, true)} style={{...indexButtonStyle, alignItems:'start'}}>
                    <img src={iconArrowUp} style={{ width: '10px', height: '10px', cursor: 'pointer', zIndex: 500 }} />
                </button>

                <button onClick={() => onReorderClick(name, false)} style={{...indexButtonStyle, alignItems:'end'}}>
                    <img src={iconArrowDown} style={{ width: '10px', height: '10px', cursor: 'pointer', zIndex: 500 }} />
                </button>


            </div>

            
        </div>
    );
        

};

export default React.memo(CustomBarChart);
