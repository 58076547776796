import './css/HomePatient.css';
import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';

import Fade from '@mui/material/Fade';


import { ctxSession, ctxPatient, ctxSnackbar, ctxPatients } from '../store';


import Slider from '@mui/material/Slider';

import Card from '../components/Card';
import CustomButton from '../components/CustomButton';

import IconButton from '@mui/material/IconButton';

import Avatar from '@mui/material/Avatar';



import {
    ResponsiveContainer ,
	LineChart ,
	CartesianGrid ,
	XAxis ,
    YAxis ,
    Tooltip  ,
    Legend  ,
    Line 
} from 'recharts';



import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';

import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import SyncIcon from '@mui/icons-material/Sync';


import PopupList from '../components/PopupList'

import Axios from '../helpers/axios';

import { MedicModel } from '../models';

import iconDateFrom from '../../assets/images/icons/dateFrom.svg'
import iconDateTo from '../../assets/images/icons/dateTo.svg'
import CustomBarChart from '../components/CustomBarChart';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { CustomSelectOption } from '../components/CustomSelectOptions';
import CustomSelectValueContainer from '../components/CustomSelectValueContainer';
import { CustomSelectMenu } from '../components/CustomSelectMenu';
import { CustomSelectControl } from '../components/CustomSelectControl';
import axios, { AxiosError, AxiosResponse } from 'axios';
import DoctorSelectionPopup from '../components/DoctorSelectionPopup';

// TODO: This module has to be updated as a whole once Home.tsx is finished

const HomePatient = () => {
    
    // Page Context register
    const session = useContext(ctxSession);
    const snackbar = useContext(ctxSnackbar);

    // Video stream register
    const localVideoRef = useRef<any>();
    const remoteVideoRef = useRef<any>();

    // Audio stream register
    const [ volume, setVolume ] = useState<any>(.66);
    const [ volumeIcon, setVolumeIcon ] = useState<any>(<VolumeUpIcon />);
    const [showVolumeSlider, setShowVolumeSlider] = useState<boolean>(false);

    // List Popup register
    const [showPopupList, setShowPopupList] = useState(false);
    const [doctors, setDoctors] = useState<MedicModel[]>([]);
    const [onlineDoctors, setOnlineDoctors] = useState<[]>([]);    


    useEffect( () => {
        
            if(!volume){
                setVolumeIcon( <VolumeOffIcon /> );
            }else{
                if(volume >= .5){
                    setVolumeIcon( <VolumeUpIcon /> );
                    // return <VolumeUpIcon />;
                }else{
                    setVolumeIcon( <VolumeDownIcon /> );
                    // return <VolumeDownIcon />;
                }
            } 
    }, [volume]);

    // WebRTC connection register
    const patientConnection = useRef<any>();

    // Peers register
    const connectedUser = useRef<any>();
    let connectedPeer = useRef<any>();
    let connectedPeerFullName = useRef<any>();
    let [connectedPeerDisplay, setConnectedPeerDisplay] = useState(false);

    // Answer register
    const receivedOffer = useRef(null);

    // Incoming call register
    const [incomingCall, setIncomingCall] = useState(false);
    const [inCall, setInCall] = useState(false);

    // CallSound register
    const audio = new Audio(require('../../assets/sounds/incomingCall.mp3'));
    const audioRef = useRef<HTMLAudioElement>(audio);
    audioRef.current.volume = 0.66;
    const audioIntervalRef = useRef<any>();

    // const hangUpAudio = new Audio(require('../../assets/sounds/hangUp.mp3'));
    // const hangUpAudioRef = useRef<HTMLAudioElement>(hangUpAudio);
    // hangUpAudioRef.current.volume = 0.1;

    // Socket register
    const [socket, setSocket] = useState<WebSocket|null>(null);

    // Candidates queue register
    let candidateQueue:any = [];

    // Fullscreen register
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

    // DateTime Selector register
    const [startDate, setStartDate] = useState(new Date());     
    const [endDate, setEndDate] = useState(new Date());

    // Analysis register
    const [analysisOptions, setAnalysisOptions] = useState<{ value: string; label: string; }[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<{ value: string; label: string; }[] | null>([]);
    const [analysisMenuOpen, setAnalysisMenuOpen] = useState(false);
    const customSelectRef = useRef<any>();
    const taxCodeRegex = /^([A-Z]{3})([A-Z]{3})(\d{2})([ABCDEHLMPRST])(\d{2})([A-M]\d{3})([A-Z])$/;

    // BarChart register
    type BrushIndicesType = {
        [key: string]: [number, number];
    };
  
    const prevStartDateRef = useRef<Date>();
    const prevEndDateRef = useRef<Date>();
    const [barCharts, setBarCharts] = useState<{ index: number, type: string, data:any, metadata:any }[]>([])
    const [swappedDrillDownCharts, setSwappedDrillDownCharts] = useState<{ index: number, type: string, data:any, metadata:any }[]>([])    
    const [swappedLiveCharts, setSwappedLiveCharts] = useState<{ index: number, type: string, data:any, metadata:any }[]>([])    
    const [brushIndices, setBrushIndices] = useState<BrushIndicesType>({});
    const handleBrushChange = (chartType:string, newIndices:[number, number]) => {
        setBrushIndices((prev:any) => ({ ...prev, [chartType]: newIndices }));
    };

    // Sync register
    const [isSyncEnabled, setIsSyncEnabled] = useState<boolean>(false);

    // Supervision request register
    const [showDoctorsPopup, setShowDoctorsPopup] = useState(false);
    const [availableDoctors, setAvailableDoctors] = useState([]);
    const openPopup = () => {
        const onSuccess = (response:any) => {
            setAvailableDoctors(response.data);
            setShowDoctorsPopup(true);
        };
    
        const onError = (error:any) => {
            console.error('Error fetching unassociated doctors:', error);
            // Handle errors (e.g., show a notification)
        };
    
        Axios(session, 'get', `/api/doctorPatient/GetAvailableDoctorsFromPatientTaxCode/${session?.data?.userData?.taxCode}`, onSuccess, onError);
    };
    

    // debug
    useEffect(() =>{
        console.log(session?.data?.userData)
    },[])



    /** CALL EVENTS */


    // Sets incomingCall as the Offer is received from the peer
    function eventCallOpen(){
        audioRef.current.play();
        document.title = document.title + ' - In coming Call';
        audioIntervalRef.current = setInterval(() => {
            audioRef.current.currentTime = 0;
            audioRef.current.play();
        }, Math.round(audioRef.current.duration*1000) );
        setIncomingCall(true);
    }
    
    // Unsets incomingCall as one of the peers hang up
    function eventCallClose(){
        audioRef.current.pause();
        document.title = document.title.split(' - ')[0];
        audioRef.current.currentTime = 0;
        clearInterval(audioIntervalRef.current);
        setIncomingCall(false);
    }


    /** CONNECTION HANDLING */
    

    useEffect( () => {
        if(!socket){
            
            // Current PeerUser
            connectedUser.current = session?.data?.userData?.taxCode;

            // Server socket connection
            const conn = new WebSocket(`wss://${window.location.hostname}:9091`);
    
            // Event onSocketOpen
            conn.onopen = () => {
                console.log("Connected to the Application Server");
            };
            
            // Event onServerMessage
            conn.onmessage = (msg) => {

                // console.log("Got message", msg.data);

                // Check that msg-payload is properly formed
                let data = null;
                try {
                    data = JSON.parse(msg.data);
                    console.log('Incoming Data:', data);
                } catch (e) {
                    console.error(e, msg.data);
                }

                // If msg.data is what exepected, send a LoginRequest
                if (data === "Server Online") {
                    conn.send(JSON.stringify({
                        type: "login",
                        name: connectedUser.current
                    }));
                    console.log(data);
                    return;
                }

                // Handle Socket events
                switch (data.type) {

                    // Login
                    case "login":
                        handleLogin(data.success);
                    break;

                    // A call (offer) is sent to this PatientPeer
                    case "offer":
                        // Update variables for answering logic
                        // receivedOffer.current = data.offer;
                        // connectedPeer.current = data.name;
                        // connectedPeerFullName.current = data.fullname;
                        handleOffer(data.offer, data.name, data.fullname);

                        // Trigger call open event
                        eventCallOpen();
                    break;

                    // An answer is sent to this PatientPeer
                    case "answer":
                        handleAnswer(data.answer);
                    break;

                    // The MedicPeer sends its ICE candidates
                    case "candidate":
                        handleCandidate(data.candidate);
                    break;

                    // The server sends ChartSync data
                    case "chart-sync-payload":
                        setBarCharts(data.chart);
                        setIsSyncEnabled(data.sync);
                    break;
                    
                    // The server responds with the required list of online users.
                    // this is used when requiring the list of online Medics.
                    case "res-online":
                        setOnlineDoctors(data.data);
                    break;

                    // The call is closed
                    case "leave":
                        handleLeave();
                        hangUp();
                    break;

                    default:
                    break;
                }
            };
            
            conn.onerror = function (err) {
                console.log("Got error", err);
            };
    
            setSocket(conn)

        }

    }, []);

    
    // Handle Login result
    const handleLogin = (success:any) => {
        if (success === false) {

            // snackbar.set([...snackbar?.data, {message: 'Sei già connesso!', severity: 'warning', millis: 2000}]);
            alert("La sessione è già aperta in un'altra scheda. Questa pagina si chiuderà automaticamente in 5 secondi.");
            setTimeout(() => {
                window.location.href = 'https://www.google.com';
            }, 5000);

        }
    }

    // Handle Offers sent to this PatientPeer
    async function handleOffer(offer:any, name:any, fullname:any) {
        try {
            receivedOffer.current = offer;
            connectedPeer.current = name;
            connectedPeerFullName.current = fullname;
    
            if (!patientConnection.current || patientConnection.current.signalingState === 'closed') {
                const configuration = {
                    iceServers: [
                        { urls: 'stun:stun.gest.cloud:3478' },
                        { urls: 'turns:stun.gest.cloud:5349', username: 'gest.cloud.turn', credential: 'xMsa7gTPC4atWnd' }
                    ]
                };
                patientConnection.current = new RTCPeerConnection(configuration);
            }
    
            patientConnection.current.onicecandidate = (event:any) => {
                if (event.candidate) {
                    send({ type: "candidate", candidate: event.candidate });
                }
            };
    
            patientConnection.current.ontrack = (event:any) => {
                if (event?.streams[0]) {
                    remoteVideoRef.current.srcObject = event.streams[0];
                }
            };
    
            await patientConnection.current.setRemoteDescription(new RTCSessionDescription(offer));
    
            const answer = await patientConnection.current.createAnswer();
            await patientConnection.current.setLocalDescription(answer);
    
            send({ type: "answer", answer: answer, name: connectedPeer.current });
    
        } catch (error) {
            console.error("Error handling offer:", error);
            // Handle error (e.g., show an error message to the user)
        }
}
    

// Handle Answers sent to this PatientPeer
async function handleAnswer(answer: any) {
    try {
        await patientConnection.current.setRemoteDescription(new RTCSessionDescription(answer));
        
        // Additional actions after setting the remote description
        // For example, you can proceed with other setup steps here

        // Stop the incoming call audio and reset the title
        audioRef.current.pause();
        document.title = document.title.split(' - ')[0];
        audioRef.current.currentTime = 0;
    } catch (error) {
        console.error("Error handling answer:", error);
        // Handle error (e.g., show an error message to the user)
    }
}

        
    // Handle ICE Candidates sent to this PatientPeer
    async function handleCandidate(candidate:any) {
        try {
            if (patientConnection.current && patientConnection.current.signalingState !== 'closed') {
                // Check if the candidate is a TURN candidate
                if (isTurnCandidate(candidate)) {
                    // Prioritize TURN candidate by adding it immediately
                    await patientConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
                } else {
                    // Queue other candidates
                    candidateQueue.push(candidate);
                }
            } 
        } catch (error) {
            console.error("Error handling candidate:", error);
            // Handle error
        }
    }
    
    // Utility function to check if a candidate is a TURN candidate
    function isTurnCandidate(candidate:any) {
        return candidate.candidate.includes('relay');
    }

    // Handles closing the call
    function handleLeave() {
            
        if(stream?.current){
            stream.current.getTracks().forEach((mst:MediaStreamTrack) => mst.stop())
        }

        snackbar?.set({message: 'Il Medico si è disconnesso.', severity: 'error'});
        setConnectedPeerDisplay(false);
        eventCallClose();

    };


    /** UTILITIES */


    // Send messages to the MedicPeer
    function send(message:any) {
        //attach the other peer username to our messages 
        if (connectedPeer.current) {
            message.name = connectedPeer.current;
        }

        // Send message through socket
        socket?.send(JSON.stringify(message));
    };


    /** @section  --ANALYSIS UTILITIES-- */

    /** Reset Dates to default
     * 
     *  @param endGap   Int representing the difference between StartDate (today) and the desired EndDate. [default: 90 days].
     * 
     */
    function resetDates(endGap:number = 90){
        let tempStartDate = new Date();
        tempStartDate.setDate(tempStartDate.getDate() - endGap);
        tempStartDate.setHours(0, 1, 0, 0);
        setStartDate(tempStartDate);
        setEndDate(new Date());
    }


    /** EVENTS */


    // onLeave Event
    useEffect(() => {

        const handleUnload = (ev:any) => {
            ev.preventDefault();
            hangUp();
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);


    // onLogOut Event
    useEffect(() => {

        // Check if User is logged out
        if(socket && session?.data?.token == ''){
            socket?.close();
        }
    }, [socket, session?.data?.token]);


    //onOnlineMedicsListReceived
    useEffect(() => {
        if (onlineDoctors && doctors) {

            // Create a Set for fast Lookup
            const onlineDoctorsSet = new Set<any>(onlineDoctors)
          
            // Map through the doctors array and add 'isOnline' property
            const updatedDoctors = doctors.map(doctor => {
                return {
                  ...doctor,
                  isOnline: onlineDoctorsSet.has(doctor.id_doctor)
                };
              });

            // Update the popup register accordingly
            setDoctors(updatedDoctors);

            // Trigger popup rendering
            // As doctors is only received after the fetch, meaning after the actual click, it is used as a trigger condition
            if(doctors.length > 0){
                if (updatedDoctors.length > 0 ){
                    setShowPopupList(true);
                }else{
                    snackbar?.set({ message: "Nessun Medico disponibile", severity: 'error', millis: 3000 });
                }
            }

        }
      }, [onlineDoctors]);


    /** ONCLICK FUNCTIONS */


    // Answer a call 
    const stream = useRef<MediaStream|null>(null);
    async function answer() {
        setInCall(true);
    
        if (receivedOffer.current) {
            try {
                const mediaStream = await navigator.mediaDevices.getUserMedia({
                    video: { width: { min: 640, ideal: 1280 }, height: { min: 480, ideal: 720 } },
                    audio: true
                });
    
                stream.current = mediaStream;
                localVideoRef.current.srcObject = mediaStream;
    
                setConnectedPeerDisplay(true);
    
                if (!patientConnection.current) {
                    const configuration = {
                        iceServers: [
                            { urls: 'stun:stun.gest.cloud:3478' },
                            { urls: 'turns:stun.gest.cloud:5349', username: 'gest.cloud.turn', credential: 'xMsa7gTPC4atWnd' }
                        ]
                    };
                    patientConnection.current = new RTCPeerConnection(configuration);
                }
    
                patientConnection.current.onicecandidate = (event:any) => {
                    if (event.candidate) {
                        send({ type: "candidate", candidate: event.candidate });
                    }
                };
    
                patientConnection.current.ontrack = (event:any) => {
                    if (event?.streams[0]) {
                        remoteVideoRef.current.srcObject = event.streams[0];
                    }
                };
    
                if (patientConnection.current && patientConnection.current.signalingState !== 'closed') {
                    patientConnection.current.addStream(mediaStream);
                }
                await patientConnection.current.setRemoteDescription(new RTCSessionDescription(receivedOffer.current));
    
                const answer = await patientConnection.current.createAnswer();
                await patientConnection.current.setLocalDescription(answer);
    
                send({ type: "answer", answer: answer, name: connectedPeer.current });
    
                eventCallClose();
    
            } catch (error) {
                console.error("Error in answering call:", error);
                alert("Error in answering call.");
            }
        } else {
            alert("No call to answer.");
        }
    }
    
    


    // HangUp a call
    function hangUp(){
        // hangUpAudioRef.current.play();
        // hangUpAudioRef.current.currentTime = 0;

        setInCall(false);
        // Trigger call close event 
        eventCallClose();

        // Close video stream
        if(stream?.current){
            stream.current.getTracks().forEach((mst:MediaStreamTrack) => mst.stop())
        }

        // Close video refs
        if(localVideoRef.current){
            localVideoRef.current.srcObject = null;
        }
        remoteVideoRef.current.srcObject = null;

        setConnectedPeerDisplay(false);

        // Send a leave-event
        send({
            type: "leave"
        });

        // Close current connection
        if(patientConnection.current){
            patientConnection.current.close();
            patientConnection.current.onicecandidate = null;
            patientConnection.current.ontrack = null;
        }

        // Reset connectedPeer to avoid unwanted notification routing
        connectedPeer.current = null;
        connectedPeerFullName.current = null;

        // Clear SyncState
        setIsSyncEnabled(false);


    }
    

    // Require Assistance Modal
    function requestAssistance(){
        
        if (session?.data?.userData?.id) {

            const patientId = session?.data?.userData?.id;
            const onSuccess = (response: any) => {

                // Check which of those Doctors are online
                send({
                    type: "req-online",
                    filter: "Doctor"
                });
                setDoctors(response.data);
            }

            Axios(session, 'get', `/api/doctorPatient/GetDoctorsFromPatientId/${patientId}`, onSuccess, () => true);
        }
    }


    // Issue Assistance Request
    function issueAssistanceRequest(target:any){
        send({
            type: "req-assistance",
            target: target
        });
    }


    // Toggle Fullscreen
    function toggleFullscreen(){
        setIsFullScreen(!isFullScreen);
    }


    /** @section --SELECT MENU FUNCTIONS-- */

    /** onClickOutsideAnalysisMenu event
     * 
     * @brief   If the AnalysisMenu is open, any click outside of the Component will collapse the Menu.
     * 
     */
    useEffect(() => {
        function handleClickOutside(event:any) {

            // Close AnalysisMenu on click outside
            if (customSelectRef.current && !customSelectRef.current.contains(event.target)) {
                setAnalysisMenuOpen(false);
            }

        }
    
        // Bind / unbind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [customSelectRef]);



    /** onPatientLoad event
     * 
     * @brief           The User changes patient through the Sidebar component.
     * 
     * @description     Any current call is HangedUp and any flag or date is restored
     *                  to their default value.
     *                  Patient's AnalysisOptions are loaded from the API response into
     *                  the AnalysisMenu and the first 4 Charts are Plotted by default.
     * 
     */
        useEffect(() => {

            // Reset the actual call state
            hangUp();
    
            // Reset Dates
            resetDates();
    
            // Unset SelectedAnalysisOptions and close the AnalysisMenu
            setSelectedOptions(null);
            setAnalysisMenuOpen(false);
    
            // Recover and Set patient's AnalysisOptions
            const onSuccess = (response:any) => {
    
                // Map the response array into AnalysisOptions
                let patientAnalysisOptions = response.data.map((option:any, index:any) => {
                    return { value: `${index + 1}`, label: option.toUpperCase() }
                })
    
                setAnalysisOptions(patientAnalysisOptions);
    
                /** Plot the first 4 charts by default
                 *  @note   This has been explicitly required for demo purposes,
                 *          just setSelectedOptions(null) to restore normal behavior.
                 */
                const chartQuantity = 4;
                let last_option = patientAnalysisOptions.length >= chartQuantity ? chartQuantity : analysisOptions.length;
                setSelectedOptions(patientAnalysisOptions.slice(0, last_option))
                
                // Close dropDown menu
                setAnalysisMenuOpen(false);
    
            }
    
            // Handle request errors
            const onError = (response:any) => {
    
                // 404 No data found
                if(response.response.status == 404){
                    setAnalysisOptions([]);
                }
    
            }
    
            // Validate UserSession and PatientData before calling the Endpoint
            if(session?.data?.userData?.taxCode && session?.data?.userData?.taxCode.match(taxCodeRegex) && session?.data?.userData?.id){
                Axios(session, 'get', `/api/analysis/GetMeasurementTypesFromTaxCodePatient/${session?.data?.userData?.taxCode}`, onSuccess, onError);
            }
    
        }, []);



    /** @section --EDIT BARCHART FUNCTIONS-- */

    /** Remove a BarChart by deselecting it from the ListView
     * 
     *  @brief  Closes the BarChart with the X button placed inside each chart's div
     *          and clears the SwappedArrays of reference to the Closed BarChart.
     * 
     */
    const handleCloseBarChart = useCallback((chartType: string) => {

        if (selectedOptions) {
            
            // Create a new array without the selected index
            const newSelectedOptions = selectedOptions.filter((option) => option.label !== chartType);

            // Update the selected options
            setSelectedOptions(newSelectedOptions);

            // Update the setswappedDrillDownCharts to remove the selected chart
            setSwappedDrillDownCharts(swappedBarCharts => swappedBarCharts.filter((chart) => chart.type !== chartType));

            // Update the setswappedLiveCharts to remove the selected chart
            setSwappedLiveCharts(swappedBarCharts => swappedBarCharts.filter((chart) => chart.type !== chartType));

        }

    }, [selectedOptions])

    /** Remove unselected BarCharts
     * 
     *  @brief  Given the AnalysisMenu selection, removes any currently plotted
     *          BarChart that is not present in the selection.
     * 
     */
    function handleRemoveBarChart() {

        // Remove unselected barCharts
        setBarCharts(prevBarCharts =>
            prevBarCharts.filter(barChart => 
                selectedOptions?.some(option => option.label === barChart.type)
        ).map((barChart, index) => ({...barChart, index})));

        // Update swapped registers as well
        setSwappedDrillDownCharts(prevBarCharts =>
            prevBarCharts.filter(barChart => 
                selectedOptions?.some(option => option.label === barChart.type)
        ).map((barChart, index) => ({...barChart, index})));

        setSwappedLiveCharts(prevBarCharts =>
            prevBarCharts.filter(barChart => 
                selectedOptions?.some(option => option.label === barChart.type)
        ).map((barChart, index) => ({...barChart, index})));

    }


    /** @section --ORDER BARCHART FUNCTIONS-- */

    /** Move the chart up or down in the BarCharts view */
    const handleReorderBarChart = useCallback((type: string, direction: boolean) => {

        // Copy the current barCharts state
        let newCharts = [...barCharts];

        // Find the index of the chart matching the specified type
        const index = newCharts.findIndex(chart => chart.type === type)

        /// Early return on chart not found
        if (index === -1) return;

        // Convert direction to swap index
        const swapIndex = direction ? index - 1 : index + 1

        /// Check that the swap index is within the array bounds, eventually early return
        if (swapIndex < 0 || swapIndex >= newCharts.length) {
            return; 
        }

        // Swap the elements
        [newCharts[index], newCharts[swapIndex]] = [newCharts[swapIndex], newCharts[index]];

        // Set the new state
        setBarCharts(newCharts);
        
    }, [barCharts])



    /** @section --DRILLDOWN BARCHART FUNCTIONS-- */


    // Utility function to calculate new brush index
    const calculateNewBrushIndexShort = (dataLength: number) => {
        if (dataLength === 0) {
            return [0, 0];
        }
    
        let startIndex = 0;
        let endIndex = dataLength - 1;
    
        if (dataLength > 5) {
            startIndex = dataLength - 5;
        }
    
        // Safeguard to ensure endIndex is never less than startIndex
        endIndex = Math.max(startIndex, endIndex);
    
        console.log(`New Brush Index: Start = ${startIndex}, End = ${endIndex}`);
        return [startIndex, endIndex];
    };
    

    /** Fetch candle DrillDown / Live data 
     * 
     *  @brief                      This function allows displaying DrillDownChart or LiveChart in the place of a default BarChart
     *                              maintaining the chart position in the ChartView.
     * 
     *  @description                Downloads DrillDown data from the API for the selected BarChart, then proceeds
     *                              to swap the selected BarChart array with the downloaded DrillDown array matching by ChartType.
     *                              The default chart is then saved in a SwappedArray of choice (DrillDown or Live) and can be easily
     *                              restored. Infact, function is used as well for displaying LiveCharts, thus accepts a custom swapperSetter
     *                              as parameter to select the target SwappedArray.
     * 
     * @param startRectangleIndex   String representing the starting index for downloading the data in ISO format.
     * @param endRectangleIndex     String representing the ending index for downloading the data in ISO format.
     * @param measurementType       String representing the ChartType [DIA, BPM, ..].
     * @param swapperSetter         Callback Function to set the desired SwappedArray. Must be a useState hook.
     * @param isLive                Boolean to set / unset the isLive flag in the API returned payload. This flag is later used for conditional rendering. [default: false]
     * 
     */
    const fetchDrillDown = useCallback( async (startRectangleIndex: string, endRectangleIndex: string, measurementType: string, swapperSetter: Function, isLive: boolean = false) => {

        console.log("Fetching drillDown..", startRectangleIndex, endRectangleIndex, measurementType)

        // Select query mode
        let queryMode = isLive ? "Live" : "";

        // Swap the BarChart with the DrillDownChart
        const onSuccess = (response:any) => {

            // Get index from previous BarChart
            let matchBarChart = barCharts.find(barChart => barChart.type.toUpperCase() === response.data.type.toUpperCase())

            // Verify that MatchBarChart exists
            if(matchBarChart && matchBarChart !== undefined) {

                // Save the original BarChart to the swapped array
                swapperSetter((prevState:any) => [...prevState, matchBarChart as { index: number, type: string, data:any, metadata:any }]);

                // Update the state
                setBarCharts((prevBarCharts) => {
                
                    // Make a copy of the BarCharts array
                    let updatedBarCharts = [...prevBarCharts];

                    // Find the index of the BarChart to update
                    let updateIndex = updatedBarCharts.findIndex(barChart => barChart.type === matchBarChart?.type);

                    // Update the BarChart in the copy of the array
                    updatedBarCharts[updateIndex] = {
                        ...updatedBarCharts[updateIndex],
                        data: response.data.data,
                        metadata: response.data.metadata
                    };

                    // Calculate the new brush index based on the new data length
                    const newBrushIndex = calculateNewBrushIndexShort(response.data.data.length);

                    // Update the brush index in the metadata
                    updatedBarCharts[updateIndex].metadata.prevBrushIndex = newBrushIndex;

                    // Return the updated BarCharts
                    return updatedBarCharts;

                });

            } else {
                console.log("matchBarChart is undefined or null");
            }

        }

        // Log error
        const onError = (response:any) => {
            console.log(response);
        }

        // Validate UserSession and PatientData before calling the Endpoint
        if(session?.data?.userData?.taxCode && session?.data?.userData?.taxCode.match(taxCodeRegex)){

            let labelString;
            measurementType === "BPM"  ? labelString = measurementType : labelString = measurementType.toLowerCase();
            Axios(session, 'get', `/api/analysis/GetLiveAnalysis/${session?.data?.userData?.taxCode}/${labelString}/${session?.data?.userData?.taxCode}/${startRectangleIndex}/${endRectangleIndex}/${queryMode}`, onSuccess, onError);

        }

    }, [barCharts, session?.data?.userData?.taxCode, session, taxCodeRegex])

    /** Restore a DrillDownChart to the BarChart format 
     * 
     * @brief   Following the same principle of fetchDrillDown, restored a previously swapped DrillDownChart
     *          or LiveChart to its BarChart view and clears the SwappedArray of choice.
     * 
     */
    const handleRestoreDrillDownChart = useCallback(async (chartType: string, swappedArray: any[], swappedSetter: Function) => {
        const chartToRestore = swappedArray.find(chart => chart.type === chartType);
    
        if (chartToRestore) {
            // Switch back to BarCharts
            setBarCharts(prevBarCharts => {
                const newBarCharts = [...prevBarCharts];
                const chartIndex = newBarCharts.findIndex(chart => chart.type === chartType);          
    
                // If there is a type match, replace the chart
                if (chartIndex !== -1) {
                    // Calculate the new brush index
                    const newBrushIndex = calculateNewBrushIndexShort(chartToRestore.data.length);
    
                    // Update the chart with new brush index
                    newBarCharts[chartIndex] = {
                        ...chartToRestore,
                        metadata: {
                            ...chartToRestore.metadata,
                            prevBrushIndex: newBrushIndex
                        }
                    };
                }
    
                return newBarCharts;
            });
    
            // Clear the Chart from the SwappedArray
            swappedSetter((prevSwappedBarCharts:any) => 
                prevSwappedBarCharts.filter((chart:any) => chart.type !== chartType)
            );
        }
    }, []);
    



    
    /** @section --FETCH BARCHART FUNCTIONS-- */

    /* Debug Bars */
    useEffect(() => {

        // console.log(barCharts);
        // console.log(barCharts.length == selectedOptions?.length)
        // console.log(swappedDrillDownCharts)

    }, [barCharts]);

    /** Download and Plot RectangleBars on AnalysisMenu selection / DatePicker change */
    useEffect(() => {

        // For each selected option, fetch data if it's not yet fetched or dates have changed
        selectedOptions?.forEach((option) => {
            if (!barCharts.some((chart) => chart.type === option.label) || startDate !== prevStartDateRef.current || endDate !== prevEndDateRef.current) {
                fetchBarChart(option);
            }
        });

        // Call handleRemoveBarChart to remove unselected barCharts
        handleRemoveBarChart();

        // Update previous dates
        prevStartDateRef.current = startDate;
        prevEndDateRef.current = endDate;
      
    }, [selectedOptions, startDate, endDate]);


    // Function to calculate a new brush index based on data length and existing index
    const calculateNewBrushIndex = (dataLength: number, existingIndex: number[]) => {
        if (dataLength === 0) return [0, 0]; // Handle no data case

        // Adjust this logic based on how you want to set the brush range
        if (dataLength <= 5) {
            // If data is less than or equal to 5, show all data
            return [0, dataLength - 1];
        } else {
            // If data is more than 5, you can set a default range or use the existing index
            return existingIndex || [dataLength - 6, dataLength - 1];
        }
    };
          
      
    /** Fetch RectangleBars from the API */
    const fetchBarChart = async (option:any) => {

        // Add fetched barChart to the barCharts array
        const onSuccess = (option: any) => (response:AxiosResponse) => {
            
            // Block data signed with a different TC than the selected patient
            if(response.data.signature && response.data.signature !== session?.data?.userData?.taxCode) return;

            // Add brushIndex for Recharts in the metadata
            // Check if there's an existing brush index for this chart type
            const existingIndex = brushIndices[option.label];
            if (existingIndex) {
            response.data.metadata.prevBrushIndex = existingIndex;
            } else {
            response.data.metadata.prevBrushIndex = [0, 0]; // Default value
            }

            // Calculate new brush index based on the fetched data
            const newDataLength = response.data.payload.length;
            const newBrushIndex = calculateNewBrushIndex(newDataLength, existingIndex);

            // Update metadata with the new brush index
            response.data.metadata.prevBrushIndex = newBrushIndex;
                handleAddBarChart(option.label, response.data.payload, response.data.metadata)
            }       
         

        // Add an empty barChart to the barCharts array
        const onError = (option: any) => (error:AxiosError) => {

            // Check if the request was cancelled to avoid state updates on an unmounted component
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                handleAddBarChart(option.label, [], []);
            }

        }

        // Validate UserSession and PatientData before calling the Endpoint
        if ((session?.data?.userData?.taxCode && session?.data?.userData?.taxCode.match(taxCodeRegex) && session?.data?.userData?.id)) {

            let labelString;
            option.label === "BPM"  ? labelString = option.label : labelString = option.label.toLowerCase();

            axios.get(`/api/analysis/GetRectangleBarsAnalysis/${session?.data?.userData?.taxCode}/${labelString}/${session?.data?.userData?.taxCode}/${startDate.toISOString()}/${endDate.toISOString()}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }                
            })
            .then(response => {

                // Redirect to onSuccess routine and clear the token
                onSuccess(option)(response)

            })
            .catch(error => {

                // Redirect to onError routine and clear the token
                onError(option)(error)

            });
        }
            
    }
    

    /** Add or Replace RectangleBars into the BarChart array */
    function handleAddBarChart(type:string, data: any, metadata:any) {

        setBarCharts((prevBarCharts:any) => {

            // Get previous index, if any
            const existingIndex = prevBarCharts.findIndex((barChart:any) => barChart.type === type);
    
            // If this type of BarChart doesn't exist yet, append it
            if (existingIndex === -1) {
                return [...prevBarCharts, {index: prevBarCharts.length, type: type,  data: data, metadata: metadata}]
                    .sort((a, b) => a.type.toLowerCase().localeCompare(b.type.toLowerCase()))
                    .map((chart, index) => ({...chart, index}));

            }else{

                // If this type of BarChart does exist, replace it
                const newBarCharts = [...prevBarCharts];
                newBarCharts[existingIndex] = {index: existingIndex, type: type,  data: data, metadata: metadata};
                return newBarCharts;

            }
        });    
    }



    
    return (

        <Fade in={true}>
            <div id="homePatient">
                <main>

                    <Card class={`${isFullScreen ? "fullscreenPatient conference" : "conference"}`} onDoubleClick={() => toggleFullscreen()}>
                        <div className="conferenceBox">
                            
                            <div className='remoteVideoContainer'>
                                <video className="remoteVideo" ref={remoteVideoRef} autoPlay playsInline>
                                </video>
                            </div>
                            { ( inCall ) &&
                                <video className="localVideo" ref={localVideoRef} autoPlay muted>

                                </video>
                            }   

                            <div className='remoteName'>
                                <h5>{connectedPeerFullName.current ? 'Dott. ' + connectedPeerFullName.current : ''}</h5>
                            </div>
                            <div className={isSyncEnabled ?  'syncContainerActive' : 'syncContainerDisabled'}>
                                <SyncIcon />
                            </div>

                            <div className='commands'>
                                { incomingCall ?
                                    <>
                                        <button className={`phone call ${incomingCall ? 'pulsing' : ''}`} onClick={answer}>
                                            <CallIcon />
                                        </button>
                                        
                                        <button className='phone hangUp' onClick={hangUp}>
                                            <CallEndIcon />
                                        </button>
                                    </>
                                    :
                                    <>
                                    {
                                        inCall ? 
                                        <>
                                        <button className='phone hangUp' onClick={hangUp}>
                                            <CallEndIcon />
                                        </button>
                                        <div className='volumeBox'>
                                            { showVolumeSlider &&
                                                <Slider
                                                    aria-label="Volume"
                                                    orientation="vertical"
                                                    // getAriaValueText={volume}
                                                    defaultValue={66}
                                                    onChange={(event: Event, newValue: number | number[]) => {
                                                        if(typeof newValue === 'number'){
                                                            
                                                            remoteVideoRef.current.volume = newValue/100;
                                                            setVolume(newValue/100);
                                                        }
                                                    }}
                                                />
                                            }
                                            <button className='phone volume' onClick={() => {
                                                setShowVolumeSlider(!showVolumeSlider);
                                            }}>
                                                {
                                                    volumeIcon
                                                }
                                                {/* <CallEndIcon /> */}
                                            </button>
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className='btn btn-primary editProfile clickable' onClick={requestAssistance}>Richiedi Assistenza</div>
                                        <PopupList show={showPopupList} onClose={() => setShowPopupList(false)} onSend={(doctorId)=>{issueAssistanceRequest(doctorId)}} doctors={doctors} />
                                        </>
                                    }   
                                    </>
                                }
                                
                                
                                {/* <button onClick={() => {
                                    if(snackbar){
                                        
                                        snackbar.set([...snackbar.data, {message: 'Errore nell\'invio della chiamata.', severity: 'error', millis: 2000}]);
                                    }
                                }}>
                                    test
                                </button> */}
                            </div>
                        </div>
                    </Card>



                    <Card title="Parametri Vitali" class="vitals">
                        <div className="measures"   style={{overflowX:'hidden', display:'block' }}>

                            <div className="measuresList" style={{marginRight:'0.35em', marginLeft:'0.60em'}}>

                                <div className='commandMenu' /* flex and justifyContent space-between to push groups of divs to left */>

                                    <div style={{display: 'flex'}} /* Leftmost group */>


                                        <div className="datePickerContainer" style={{opacity: 1, pointerEvents: 'auto'}}>
                                            <img alt= "iconDateFrom" src={iconDateFrom} style={{margin:'0.20em 0 0 0.25em', width: '20px', height: '20px'}} />
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date: Date) => setStartDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={new Date()}
                                                className='custom-date-picker'
                                            />
                                        </div>
                                        
                                        <div className="datePickerContainer" style={{opacity: 1, pointerEvents: 'auto'}}>
                                            <img alt="iconDateTo" src={iconDateTo} style={{margin:'0.20em 0 0 0.25em', width: '20px', height: '20px'}} />
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date: Date) => setEndDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                minDate={startDate}
                                                maxDate={new Date()}
                                                className='custom-date-picker'
                                            />
                                        </div>

                                        <div ref={customSelectRef} style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', opacity: 1, pointerEvents: 'auto'}}>

                                            <Select
                                                options={analysisOptions}
                                                menuIsOpen={analysisMenuOpen}
                                                onMenuOpen={() => setAnalysisMenuOpen(true)}
                                                onMenuClose={() => setAnalysisMenuOpen(false)}
                                                isSearchable={false}
                                                hideSelectedOptions={false}
                                                closeMenuOnSelect={false}
                                                backspaceRemovesValue={false}
                                                isMulti
                                                value={selectedOptions}
                                                onChange={(newValue) => {setSelectedOptions(newValue as { value: string; label: string; }[]);}}
                                                components={{ Option: CustomSelectOption,
                                                            ValueContainer: CustomSelectValueContainer,
                                                            Control: CustomSelectControl,
                                                            ClearIndicator: () => null, 
                                                            IndicatorSeparator: () => null,
                                                            Menu: CustomSelectMenu
                                                }}
                                                styles={{
                                                    control: (base:any) => ({
                                                        ...base,
                                                        minHeight: '31px',
                                                        height: '31px',
                                                        backgroundColor: 'var(--gray-800)',
                                                        borderColor: 'lightgray',
                                                        borderRadius: '5px',
                                                        color: 'white',
                                                        paddingLeft: '5px',
                                                        paddingTop:'0.1vw',
                                                        margin: '-0.05em 0 0 0'
                                                    }),
                                                    container: (base:any) => ({
                                                        ...base,
                                                        minHeight: '28px',
                                                        height: '28px',
                                                        margin: '0.9em 1em 0 0'
                                                    }),
                                                    indicatorsContainer: (base:any) => ({
                                                        ...base,
                                                        minHeight: '28px',
                                                        height: '28px',
                                                    }),
                                                    dropdownIndicator: (base:any) => ({
                                                        ...base,
                                                        color: 'white'
                                                    }),
                                                    multiValue: (base:any) => ({
                                                        ...base,
                                                        backgroundColor: 'gray'
                                                    }),
                                                    multiValueLabel: (base:any) => ({
                                                        ...base,
                                                        color: 'white'
                                                    }),
                                                    menu: (base:any) => ({
                                                        ...base,
                                                        backgroundColor: 'var(--gray-800)',
                                                        zIndex: 200
                                                    }),
                                                    menuList: (base:any) => ({
                                                        ...base,
                                                        display: 'flex',
                                                        zIndex: 202,
                                                        width: '250px',
                                                        flexDirection: 'column',
                                                        backgroundColor: 'var(--gray-800)',
                                                        boxShadow: '0 0 1px 1px rgba(225, 225, 225, 0.4)',
                                                        borderRadius: '3px',      
                                                    }),
                                                    option: (base:any, state:any) => ({
                                                        ...base,
                                                        backgroundColor: state.isSelected 
                                                        ? state.isFocused ? 'var(--green-2)' : 'var(--gray-700)' 
                                                        : state.isFocused ? 'var(--green-2)' : null,
                                                        padding: '10px',
                                                        color: 'white',
                                                        maxHeight: '35px',
                                                        height: '35px',
                                                        minHeight: '35px',
                                                        ':active': {
                                                            backgroundColor: 'var(--green-1)',
                                                        },
                                                    })
                                                }}
                                            />

                                        </div>

                                    </div>

                                    <div style={{display: 'flex', justifyContent:'flex-end'}} /* Rightmost group */>

                                        {/* <div style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', maxWidth: '100px', marginTop:'1em', marginLeft:'0.5em', backgroundColor: 'var(--gray-800)'}}>
                                            <button className='plotButton' onClick={fetchBarChart}>PLOT</button>
                                        </div> */}

                                    </div>

                                </div>


                            {/* Separator */}
                            {/* <div style={{height:'10px'}}></div> */}


                            {/* Plot Area */}
                            {barCharts.map((chart) => {
                                const chartData = chart.data.map((item:any) => {
                                    let date = new Date(item.measurementId);
                                    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                                    return {
                                        name: date.toLocaleString('it-IT', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            hour: '2-digit', 
                                            minute: '2-digit', 
                                            second: '2-digit',
                                            hour12: false,
                                        }),
                                        max: item.measurementBars.max,
                                        min: item.measurementBars.min,
                                        start: item.measurementId,
                                        end: item.measurementEnd
                                    };
                                });

                                return (
                                    <div key={chart.index} style={{width:'100%', height:'230px', opacity: 1, transition: 'opacity 0.5s'}}>
                                        <CustomBarChart 
                                            name={chart.type}
                                            data={chartData} 
                                            metadata={chart.metadata}
                                            color1="#8884d8" 
                                            color2="#82ca9d" 
                                            barColor='var(--green-1)'
                                            index={chart.index} 
                                            onClose={(index:any) => handleCloseBarChart(index)}
                                            onRectangleClick={(startIndex:string, endIndex:string, measurementType:string) => fetchDrillDown(startIndex, endIndex, measurementType, setSwappedDrillDownCharts)}
                                            onUndoClick={(chartType: string) => handleRestoreDrillDownChart(chartType, swappedDrillDownCharts, setSwappedDrillDownCharts)}
                                            onReorderClick={(chartType: string, direction: boolean) => handleReorderBarChart(chartType, direction)}
                                            show={true} 
                                            alerts={{temp:false, BPM:false, spo2:false, dia:false, sys:false}}
                                            newBrushState={chart.metadata.prevBrushIndex}
                                            />
                                    </div>
                                );
                            })}

                            </div>
                            </div>
                    </Card>

                    <div className="anagraphics">
                        <Card class="anag h-100" title="Scheda Paziente">

                        <div style={{display:'flex', flexDirection:'row', height:'100%'}}>

                            <div className='avatar ms-2 me-4 h-100'>
                                <Avatar sx={{ width: 150, height: 150, fontSize: 100 }}>
                                    {session?.data?.userData?.firstname ? `${session?.data?.userData?.firstname[0].toUpperCase()}${session?.data?.userData?.lastname[0].toUpperCase()}` : ""}
                                </Avatar>                              
                                {/* <CustomButton class="editProfile w-100">Edit Profile</CustomButton> */}
                            </div>


                            <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
                                <div className='anagData'>
                                    <span>Nome e Cognome</span>
                                    <h4>{session?.data?.userData?.firstname + " " + session?.data?.userData?.lastname}</h4>
                                </div>
                                <div className='anagData'>
                                    <span>Data di Nascita</span>
                                    <h4>
                                    {session?.data?.userData?.birthDate 
                                        ? new Date(session.data.userData.birthDate).toLocaleDateString('it-IT', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric'
                                        })                                        
                                        : 'Informazioni non disponibili'}
                                    </h4>                                
                                </div>
                                <div className='anagData'>
                                    <span>Codice Fiscale</span>
                                    <h4>{session?.data?.userData?.taxCode}</h4>
                                </div>
                                <div className='anagData'>
                                        <span>Supervisione Medica</span>
                                        <h4><button className="btn" onClick={openPopup}>Richiedi</button></h4>
                                    </div>
                                    {showDoctorsPopup && session?.data?.userData?.taxCode && (
                                        <DoctorSelectionPopup
                                            show={showDoctorsPopup}
                                            onClose={() => setShowDoctorsPopup(false)}
                                            doctors={availableDoctors}
                                            session={session}
                                        />
                                    )}
                                </div>
                            </div>

                        </Card>
                       
                    </div>

                </main>
            </div>
        </Fade>

    );
};
export default React.memo(HomePatient);


// http://localhost:3001/