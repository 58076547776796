import './css/Auth.css';
import React from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom'


import LogIn from './LogIn';
import ForgotPassword from './ForgotPassword';
import SignUp from './SignUp';

import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

import importer from '../helpers/importer';

// import importer from '../helpers/importer'

const Auth = () => {

    const particlesInit = useCallback(async (engine:any) => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container:any) => {
        await console.log(container);
    }, []);
    
    return (
        <>
        <Particles id="tsparticles"
        options={
            {
                background: {
                    color: {
                      value: "#004162"
                    },
                },
                fpsLimit: 60,
                interactivity: {
                  detect_on: "canvas",
                  events: {
                    onclick: { enable: true, mode: "push" },
                    resize: true
                  },
                  modes: {
                    bubble: { distance: 400, duration: 2, opacity: 0.8, size: 40, speed: 3 },
                    grab: { distance: 400, links: { opacity: 1 } },
                    push: { quantity: 4 },
                    remove: { quantity: 2 },
                    repulse: { distance: 200, duration: 0.4 }
                  }
                },
                particles: {
                  color: {
                    value: ['#fff', '#e84855'],
                    // value: ['#f00', '#0f0', '#00f']
                    },
                  links: {
                    color: "#fff",
                    distance: 150,
                    enable: true,
                    opacity: 0.4,
                    width: 1
                  },
                  move: {
                    attract: { enable: false, rotateX: 600, rotateY: 1200 },
                    bounce: false,
                    direction: "none",
                    enable: true,
                    out_mode: "out",
                    random: false,
                    speed: 1.5,
                    straight: false
                  },
                  rotate: {
                    animation: {
                      enable: true,
                      speed: 10,
                      sync: false
                    }
                  },
                    number: {
                        density: {
                            enable: true,
                            area: 800 
                        },
                        value: 30
                    },
                  opacity: {
                    animation: { enable: true, minimumValue: 0.5, speed: 1, sync: false },
                    random: false,
                    value: 1
                  },
                  shape: {
                    character: [
                      {
                        fill: true,
                        font: "Verdana",
                        style: "",
                        value: "iHosp".split(""),
                        weight: "400"
                      }
                    ],
                    polygon: { nb_sides: 5 },
                    // stroke: { color: "random", width: 1 },
                    type: "char"
                  },
                  size: {
                    anim: { enable: true, minimumValue: 8, speed: 20, sync: false },
                    random: { minimumValue: 8, enable: true },
                    value: 24
                  }
                },
                detectRetina: true
              }
        } init={particlesInit} loaded={particlesLoaded} />

        <div id="Auth" >
            <div className={"h-100 " + "container"}>
                <Routes>
                    <Route path="/" element={<LogIn /*login = {props.login}*/ />} />
                    <Route path="/ForgotPassword" element={<ForgotPassword />} />
                    <Route path="/SignUp" element={<SignUp />} />
                    <Route
                        path="*"
                        element={<Navigate to="/" />}
                    />
                </Routes>
            </div>
        </div >
        </>
       
    );
};
export default React.memo(Auth);