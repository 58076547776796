import Axios from '../helpers/axios';
import React, { CSSProperties, useState } from 'react';

type Doctor = {
    id: string;
    firstname: string;
    lastname: string;
    // Include other properties from MedicModel if necessary
};

type DoctorSelectionPopupProps = {
    show: boolean;
    onClose: () => void;
    doctors: any;
    session: any;
};

const DoctorSelectionPopup: React.FC<DoctorSelectionPopupProps> = ({ show, onClose, doctors, session }) => {
    const [selectedDoctor, setSelectedDoctor] = useState<string>(doctors.length > 0 ? doctors[0].id : '');

    React.useEffect(() => {
        // Update the selected doctor when the doctors list changes
        if (doctors.length > 0) {
            setSelectedDoctor(doctors[0].id);
        }
    }, [doctors]);
    
    const confirmSelection = () => {
        const onSuccess = (response:any) => {
            console.log('Association Created:', response.data);
            // Handle successful association here (e.g., show a success message)
            onClose(); // Close the popup after successful operation
        };

        const onError = (error:any) => {
            console.error('Error creating doctor-patient association:', error);
            // Handle errors here (e.g., show an error message)
        };

        Axios(session, 'post', `/api/doctorPatient/createAssociation/${selectedDoctor}/${session?.data?.userData?.taxCode}`, onSuccess, onError);
    };
    
    const handleOverlayClick = () => {
        onClose();
    };

    const handlePopupClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation(); // Prevents the click from propagating to the overlay
    };

    return (
        <div style={{ ...styles.overlay, display: show ? 'flex' : 'none' }} onClick={handleOverlayClick}>
            <div style={styles.popup} onClick={handlePopupClick}>
                <button style={styles.closeButton} onClick={onClose}>X</button>
                <h2>Seleziona un Medico Curante</h2>
                <select value={selectedDoctor} onChange={(e) => setSelectedDoctor(e.target.value)}>
                    {doctors.map((doctor:any) => (
                        <option key={doctor.id} value={doctor.id}>{doctor.firstname} {doctor.lastname}</option>
                    ))}
                </select>
                <button style={styles.confirmButton} onClick={confirmSelection}>Confirm</button>
            </div>
        </div>
    );
};

export default DoctorSelectionPopup;

// Add the styles as previously defined


// Styles
const styles: { [key: string]: CSSProperties } = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'opacity 0.3s ease-in-out',
        zIndex: 1000,
        display: 'none', // default to none
    },
    popup: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
        position: 'relative',
        minWidth: '300px',
        maxWidth: '600px',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'transparent',
        cursor: 'pointer',
        fontSize: '16px',
        border: 'none',
    },
    confirmButton: {
        marginTop: '20px',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        background: '#4CAF50',
        color: 'white',
        cursor: 'pointer',
        fontSize: '16px',
        display: 'block',
        width: '100%',
        textAlign: 'center',
    }
};