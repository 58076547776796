import './css/Navbar.css'

import React, { useState, useMemo, useContext } from "react";

import importer from '../helpers/importer'

import ButtonBase from '@mui/material/ButtonBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';

import Avatar from '@mui/material/Avatar';


import { useNavigate } from 'react-router-dom'

import Axios from '../helpers/axios';

import { ctxSession } from '../store';


import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Navbar = (/*props: HeaderProps*/) => {
    const navigate = useNavigate();

    const session = useContext(ctxSession);
    const user : any = useMemo(( ) => {
        if(session && session.data && session.data.userData){
            const { userData } = session.data;
            
            if( session.data.userData.roles.includes('patient') ){
                let fullname : string = userData.firstname[0].toUpperCase() + userData.firstname.slice(1).toLowerCase()
                                        + ' ' + userData.lastname[0].toUpperCase() + userData.lastname.slice(1).toLowerCase()
                return {
                    fullname,
                    username: userData.taxCode
                }
                
            }else if( session.data.userData.roles.includes('doctor') ){
                let fullname : string = userData.firstname[0].toUpperCase() + userData.firstname.slice(1).toLowerCase()
                + ' ' + userData.lastname[0].toUpperCase() + userData.lastname.slice(1).toLowerCase()
                return {
                    fullname,
                    username: userData.email
                }

            }else if( session.data.userData.roles.includes('control') ){
                let fullname : string = userData.firstname[0].toUpperCase() + userData.firstname.slice(1).toLowerCase()
                + ' ' + userData.lastname;
                return {
                    fullname,
                    username: userData.email
                }

            }
            
        }

        return {
            fullname: '',
            username: ''
        }
    }, [session?.data?.userData] );


    const logout = () => {
        
        const onSuccess = () => {
            console.log('Navbar logout')
            if(session && session.set){
                localStorage.removeItem('token');
                session.set({...session.data, token: ''});
            }
        }
        
        Axios(session, 'get', `/api/auth/logout`, onSuccess, () => true);
    }


    const [language, setLanguage] = useState<string>('it')

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const switchLang = () => {
        if (language === 'it') {
            setLanguage('gb');
        } else {
            setLanguage('it');
        }
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };
    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }


    const avatarH = 30;
    const avatarW = 30;
    const stringToColor = (string: string) => {
        let hash = 0;
        let i;
        
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
                hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        
        let color = '#';
        
        for (i = 0; i < 3; i += 1) {
                const value = (hash >> (i * 8)) & 0xff;
                color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        
        return color;
    }
    const stringAvatar = (name: string) => {
        return {
            sx: {
                fontSize: 12,
                height: avatarH,
                width: avatarW,
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    return (
        <>
        <nav id="gestNavbar">
            <div className="leftBox">
                <img id="logo"
                    src={importer.img.require("iHosp_Logo.png")}
                    alt=""
                    onClick={() => { navigate('/') }}
                />

            </div>
            <div className="middleBox">
            </div>

            <div className="utils">
                <ButtonBase
                    onClick={handleToggle}
                    ref={anchorRef}
                    className={"profile"}
                >
                        <Avatar 
                        {...stringAvatar(user.fullname || '   ')}
                        />
                        
                        <div className={"user"}>
                            <h6>{user.fullname || ''}</h6>
                            <span>{user.username || ''}</span>
                        </div>
                        <div className={'arrow'}>
                            { open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                        </div>
                    
                </ButtonBase>
            </div>
        </nav>


        
        
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            transition
            disablePortal
            className='profileMenu'
            style={{zIndex:'4000'}}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: 'right top',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>

                            <div onClick={logout} style={{padding:'5px', cursor:'pointer', zIndex:'4000'}}>
                                logout
                            </div>
                            
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
        
        </>
    );
};
export default React.memo(Navbar);