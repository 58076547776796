import 'react-datepicker/dist/react-datepicker.css';
import './css/Home.css';

import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from 'react';
import { ctxSession, ctxPatient, ctxSnackbar, ctxPatients } from '../store';
import Axios from '../helpers/axios'

import iconDateFrom from '../../assets/images/icons/dateFrom.svg'
import iconDateTo from '../../assets/images/icons/dateTo.svg'

import Slider from '@mui/material/Slider';
import Card from '../components/Card';
import Fade from '@mui/material/Fade';
import Avatar from '@mui/material/Avatar';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import SyncIcon from '@mui/icons-material/Sync';

import Select from 'react-select';
import { CustomSelectOption } from '../components/CustomSelectOptions';
import CustomSelectValueContainer from '../components/CustomSelectValueContainer';
import { CustomSelectMenu } from '../components/CustomSelectMenu';
import { CustomSelectControl } from '../components/CustomSelectControl';
import { PatientModel } from '../models';
import CustomBarChart from '../components/CustomBarChart';
import DatePicker from "react-datepicker";
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';

// Memo test patients: PLZZNT76S25C351J, PLVSST78L03C351T

type BrushIndicesType = {
    [key: string]: [number, number];
  };
  


const Home = () => {
    
    // Page register
    const session = useContext(ctxSession);
    const patient = useContext(ctxPatient);
    const snackbar = useContext(ctxSnackbar);    
    const patients = useContext(ctxPatients);

    // Video stream register
    const localVideoRef = useRef<any>();
    const remoteVideoRef = useRef<any>();
    let [isCallButtonEnabled, setIsCallButtonEnabled] = useState<boolean>(true)
    let [isCalling, setIsCalling] = useState<boolean>(false);

    // Volume register
    const [ volume, setVolume ] = useState<any>(.66);
    const [ volumeIcon, setVolumeIcon ] = useState<any>(<VolumeUpIcon />);
    const [showVolumeSlider, setShowVolumeSlider] = useState<boolean>(false);
    useEffect( () => {
        
            if(!volume){
                setVolumeIcon( <VolumeOffIcon /> );
            }else{
                if(volume >= .5){
                    setVolumeIcon( <VolumeUpIcon /> );
                    // return <VolumeUpIcon />;
                }else{
                    setVolumeIcon( <VolumeDownIcon /> );
                    // return <VolumeDownIcon />;
                }
            } 
    }, [volume]);
    
    // CallSound register
    const audio = new Audio(require('../../assets/sounds/incomingCall.mp3'));
    const audioRef = useRef<HTMLAudioElement>(audio);
    audioRef.current.volume = 0.66;
    const audioIntervalRef = useRef<any>();

    // GuestUser register
    const taxCodeRegex = /^([A-Z]{3})([A-Z]{3})(\d{2})([ABCDEHLMPRST])(\d{2})([A-M]\d{3})([A-Z])$/;
    let [isGuestConnected, setIsGuestConnected] = useState<boolean>(false)

    // WebRTC connection register
    const medicConnection = useRef<any>();

    // MediaStream register
    const stream = useRef<MediaStream|null>(null);

    // Peers register
    const connectedMedic = useRef<any>();
    let connectedPeer = useRef<any>();

    // Socket register
    const [socket, setSocket] = useState<WebSocket|null>(null);

    // Snackbar register
    const snackRef = useRef<any>();
    useEffect(() => {
        snackRef.current = snackbar;
    }, [snackbar]);
    const callSnackIndexRef = useRef<any>();

    // Fullscreen register
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

    // DateTime Selector register
    const [startDate, setStartDate] = useState(new Date());     
    const [endDate, setEndDate] = useState(new Date());

    // LiveToggle register
    const [isLiveToggled, setIsLiveToggled] = useState<boolean>(false);
    const [isChartOpacityToggled, setIsChartOpacityToggled] = useState<boolean>(false);

    // Analysis List register
    const [analysisOptions, setAnalysisOptions] = useState<{ value: string; label: string; }[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<{ value: string; label: string; }[] | null>([]);
    const [analysisMenuOpen, setAnalysisMenuOpen] = useState(false);
    const customSelectRef = useRef<any>();

    // BarChart register
    const [barCharts, setBarCharts] = useState<{ index: number, type: string, data:any, metadata:any }[]>([])
    const [swappedDrillDownCharts, setSwappedDrillDownCharts] = useState<{ index: number, type: string, data:any, metadata:any }[]>([])    
    const [swappedLiveCharts, setSwappedLiveCharts] = useState<{ index: number, type: string, data:any, metadata:any }[]>([])    
    const [brushIndices, setBrushIndices] = useState<BrushIndicesType>({});
    const handleBrushChange = (chartType:string, newIndices:[number, number]) => {
        setBrushIndices(prev => ({ ...prev, [chartType]: newIndices }));
    };

    // LiveData register
    const prevStartDateRef = useRef<Date>();
    const prevEndDateRef = useRef<Date>();
    const liveIntervalID = useRef<ReturnType<typeof setInterval>>();

    // Request cancellation
    const cancelTokenBarchartSourceRef = useRef<CancelTokenSource[] | null>(null);

    // Sync register
    const [isSyncEnabled, setIsSyncEnabled] = useState<boolean>(false);
    const [isSync, setIsSync] = useState<boolean>(false);
    const [isSyncConnectionClosed, setIsSyncConnectionClosed] = useState<boolean>(true);
    const updateBrushState = (chartType:string, newBrushIndex:number[]) => {
        setBarCharts(prevBarCharts => {
            return prevBarCharts.map(chart => {
                if (chart.type === chartType) {
                    return {
                        ...chart,
                        metadata: {
                            ...chart.metadata,
                            prevBrushIndex: newBrushIndex,
                        },
                    };
                }
                return chart;
            });
        });
    };

    // Candidates queue register
    let candidateQueue:any = [];
    


    // TODO: FIx the Snackbar's undefined behavior (after the first use it does not accept clicks or does not close properly)

    useEffect(() => {
        if(isSyncEnabled) console.log("BC", barCharts)
    },[barCharts, ])

    /** CONNECTION HANDLING */

    /** @section --MAIN CALL SWITCHING LOGIC-- */

    /** Send / Receive messages from / to the WebSocket signaling server */
    useEffect( () => {

        // Check that no socket connection is instantiated already
        if(!socket){
            
            // Set current MedicUser using session ID
            connectedMedic.current = session?.data?.userData?.id;

            // Open server socket connection
            const conn = new WebSocket(`wss://${window.location.hostname}:9091`);

            // Catch onSocketOpen event
            conn.onopen = function () {
                console.log("Connected to the Application Server");
            };
            
            // Catch onMessage event every time the signaling server sends a packet
            conn.onmessage = function (msg) {

                // Check that msg-payload is properly formed (debug)
                let data = null;
                try{
                    data = JSON.parse(msg.data);
                    console.log('Incoming Data:', data)
                }catch(e){
                    console.error(e, msg.data)
                }

                // If msg.data is what exepected, send a LoginRequest
                /** 
                 * @note    In a production environment is suggested to login
                 *          using a password, being that the ID can be forged
                 *          as it's not unique like a telephone number.
                 */
                if (data === "Server Online"){
                    conn.send(JSON.stringify({
                        type: "login",
                        name: connectedMedic.current
                    }));
                    console.log(data)
                    return;
                }

                // Catch and Handle WebSocket events
                switch (data.type) {

                    // Login
                    case "login":
                        handleLoginResult(data.success);
                        break;
        
        
                    // A call (offer) is sent to this MedicPeer
                    case "offer":
                        handleOffer(data.offer, data.name);
                        break;
        
        
                    // An answer is sent to the Peer or any other peer
                    case "answer":
                        handleAnswer(data.answer);
                        break;
        
        
                    // The Peer sends its ICE candidates
                    case "candidate":
                        handleCandidate(data.candidate);
                        break;
        
        
                    // The Peer leaves the connection
                    case "leave":
                        handleLeave();
                        hangUp();
                        setIsSyncEnabled(false);
                        setIsSync(false);
                        break;
        
        
                    // The Peer or any other called peer is busy, or the MedicPeer itself is busy
                    case "busy":
                        snackRef.current.set({severity: 'warning', message: `You or Patient ${data.targetId} is currently in a call.`});
                        break;
        
                        
                    // Displays notifications
                    case "peer-offline":
                        hangUp();
                        if(snackbar){
                            snackRef.current.set({message: data.payload});
                        }
                        break;


                    // Guest is connected to the TempRoom
                    case "guest-online":
                        if(snackbar){
                            snackRef.current.set({message: data.payload});
                        }
                        setIsGuestConnected(true);

                        break;


                    // A Patient requires assistance
                    case "res-assistance":
                        if(snackbar){
                            const JSXMessage = <div>
                                                    <p>Il Paziente {data.from} richiede Assistenza</p> 
                                                    <div style={{zIndex:'3000'}}>
                                                        <button style={{margin: "0 5px 0 5px", zIndex:'3000'}} onClick={() => {switchPatient(snackRef.current.data[0].data); snackbar.hide(snackbar.data.length)}} > Accetta </button>
                                                        <button style={{margin: "0 5px 0 5px", zIndex:'3000'}} onClick={() => {snackbar.hide(snackbar.data.length)}} > Rifiuta </button>
                                                    </div> 
                                                </div>
                            snackRef.current.set({
                                message: JSXMessage, 
                                severity: 'warning', 
                                millis: 20000, 
                                data: data.from
                            });
                        }

                        break;
        
                    default:
                        break;
                }
            };
            
            conn.onerror = function (err) {
                console.log("Got error", err);
            };

            setSocket(conn)
        }

    }, []);

    
    /** @section --CALL SWITCHING LOGIC HANDLERS-- */
    
    /** Handle Login success or failure
     * 
     *  @brief  Redirects the current user away in case of
     *          invalid login. (user-already-registered)
     * 
     */
    const handleLoginResult = (success:boolean) => {

        // If the server returns an invalid login, redirect the current user away
        if (success === false) {
                                    
            alert("La sessione è già aperta in un'altra scheda. Questa pagina si chiuderà automaticamente tra 5 secondi.");
            setTimeout(() => {
                window.location.href = 'https://www.google.com';
            }, 5000);

        }

    }

    /** Creates an Answer to Offers received from the Peer */
    async function handleOffer(offer:any, name:any) {
        try {
            // Set new connectedPeer
            connectedPeer.current = name;
    
            // Set Offer as RemoteDescription
            await medicConnection.current.setRemoteDescription(new RTCSessionDescription(offer));
    
            // Create an answer to the offer
            const answer = await medicConnection.current.createAnswer();
            await medicConnection.current.setLocalDescription(answer);
    
            // Send the answer to the peer
            send({
                type: "answer",
                answer: answer
            });
        } catch (error) {
            // Handle any errors
            if (snackbar) {
                snackbar.set({ message: `Errore nella risposta: ${error}`, severity: 'error' });
            }
            console.error("Error in handleOffer:", error);
        }
    }
    

    /** Handle Answer received from the Peer and set it as RemoteDescription */
    async function handleAnswer(answer:any) {
        try {
            // Set Answer as RemoteDescription
            await medicConnection.current.setRemoteDescription(new RTCSessionDescription(answer));
    
            // Other UI related code
            audioRef.current.pause();
            document.title = document.title.split(' - ')[0];
            audioRef.current.currentTime = 0;
            snackbar?.hide(callSnackIndexRef.current);
    
            // Enable Sync System
            setIsSyncEnabled(true);
        } catch (error) {
            // Handle any errors
            console.error("Error in handleAnswer:", error);
        }
    }
        
    /** Handle receiving a Candidate from a Peer */
    async function handleCandidate(candidate:any) {
        try {
            if (medicConnection.current && medicConnection.current.signalingState !== 'closed') {
                await medicConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
            }else{
                candidateQueue.push(candidate);
            }
        } catch (error) {
            // Handle any errors
            console.error("Error in handleCandidate:", error);
        }
    }

    /** Handle Peer leaving the call
     * 
     *  @brief  Closes the MediaStream as the Peer is left. If the Peer is a GuestPeer,
     *          destroys the TempRoom and the sets IsGuestConnected register accordingly.
     * 
     */
    function handleLeave() {
            
        // Close video tracks
        if(stream?.current){
            stream.current.getTracks().forEach((mst:MediaStreamTrack) => mst.stop())
        }

        // Discern between Regular User and TempUser to display the proper snackbar
        if(!taxCodeRegex.test(connectedPeer.current) && connectedPeer.current && connectedPeer.current !== undefined){

            // Special GuestPeer disconnected, it is required to clear the TempRoom and unflag the IsGuestConnected register
            if(snackbar){
                snackRef.current.set({severity: 'warning', message: 'Il Consulente si è disconnesso.'});

                // Delete the temproom
                console.log("@handleLeave: Destroying TempRoom..")
                deleteTempRoom();
                setIsGuestConnected(false);

            }

        }else{

            // Normal Peer disconnected, no particular actions are required
            if(snackbar){
                snackRef.current.set({severity: 'warning', message: 'Il Paziente si è disconnesso.'});
            }

            // Clear SyncState
            setIsSync(false);

        }
    };




    /** UTILITIES */

    /** @section  --CALL UTILITIES-- */

    /** Send any message to the WebSocket */
    function send(message:any) {
        
        // Attach the PeerUsername to any Message
        message.name = connectedPeer.current;

        // Send message through the signaling server WebSocket
        socket?.send(JSON.stringify(message));

    };




    /** @section  --ANALYSIS UTILITIES-- */

    /** Reset Dates to default
     * 
     *  @param endGap   Int representing the difference between StartDate (today) and the desired EndDate. [default: 90 days].
     * 
     */
    function resetDates(endGap:number = 90){
        let tempStartDate = new Date();
        tempStartDate.setDate(tempStartDate.getDate() - endGap);
        tempStartDate.setHours(0, 1, 0, 0);
        setStartDate(tempStartDate);
        setEndDate(new Date());
    }




    /** EVENTS */

    /** @section --SYSTEM EVENTS-- */

    /** onLeave event
     * 
     *  @brief  The User leaves the App by closing the window or refreshing,
     *          TempRoom is destroyed and the HangUp routine is started whereas
     *          the session is still valid.
     * 
     */
    useEffect(() => {

        const handleUnload = (ev:any) => {

            // Block default event propagation
            ev.preventDefault();

            // Destroy TempRoom if any
            console.log("Destroying TempRoom..")
            deleteTempRoom();

            // Continue hanging up
            hangUp();
        };

        window.addEventListener('beforeunload', handleUnload);

        // Clean memory on unmount
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };

    }, []);

    /** onLogOut event
     * 
     *  @brief  The User leaves the App through the LogOut function,
     *          TempRoom is destroyed and the Socket is closed.
     * 
     */
    useEffect(() => {

        // Check if User is logged out
        if(socket && session?.data?.token === ''){

            // Clear the TempRoom
            deleteTempRoom();

            // Close the socket
            // This triggers a different logic in the server rather than hangUp (which is a simple leave)
            socket?.close();
        }

    }, [socket, session?.data?.token]);

    /** onPatientChange event
     * 
     * @brief           The User changes patient through the Sidebar component.
     * 
     * @description     Any current call is HangedUp and any flag or date is restored
     *                  to their default value.
     *                  Patient's AnalysisOptions are loaded from the API response into
     *                  the AnalysisMenu and the first 4 Charts are Plotted by default.
     * 
     */
    useEffect(() => {

        // Reset the actual call state
        hangUp();

        // Reset UI flags
        setIsLiveToggled(false);

        // Reset Dates
        resetDates();

        // Unset SelectedAnalysisOptions and close the AnalysisMenu
        setSelectedOptions(null);
        setAnalysisMenuOpen(false);

        // Recover and Set patient's AnalysisOptions
        const onSuccess = (response:any) => {

            // Map the response array into AnalysisOptions
            let patientAnalysisOptions = response.data.map((option:any, index:any) => {
                return { value: `${index + 1}`, label: option.toUpperCase() }
            })

            setAnalysisOptions(patientAnalysisOptions);

            /** Plot the first 4 charts by default
             *  @note   This has been explicitly required for demo purposes,
             *          just setSelectedOptions(null) to restore normal behavior.
             */
            const chartQuantity = 4;
            let last_option = patientAnalysisOptions.length >= chartQuantity ? chartQuantity : analysisOptions.length;
            setSelectedOptions(patientAnalysisOptions.slice(0, last_option))
            
            // Close dropDown menu
            setAnalysisMenuOpen(false);

        }

        // Handle request errors
        const onError = (response:any) => {

            // 404 No data found
            if(response.response.status == 404){
                setAnalysisOptions([]);
            }

        }

        // Validate UserSession and PatientData before calling the Endpoint
        if(patient?.data?.tax_code && patient?.data?.tax_code.match(taxCodeRegex) && session?.data?.userData?.id){
            Axios(session, 'get', `/api/analysis/GetMeasurementTypesFromTaxCode/${patient?.data?.tax_code}/${session?.data?.userData?.id}`, onSuccess, onError);
        }

    }, [patient?.data]);




    /** ANALYSIS */

    /** @section --REQUIRE ASSISTANCE FUNCTIONS-- */

    /** A new Patient is selected through the RequireAssistance logic
     * 
     *  @brief  Refresh Patient data and purge SwapArrays each time an AssistanceRequest is accepted.
     * 
     */
    const switchPatient = (newPatientTaxCode:string) =>{

        // Recover patient info using the taxcode
        const onSuccess = (response: any) => {

            if(patient?.set){
                
                const newCurrentPatient: PatientModel = {
                    id: response.data.id,
                    tax_code: response.data.tax_code,
                    date_of_birth: response.data.date_of_birth,
                    fullname: response.data.firstname + " " + response.data.lastname,
                    firstname: response.data.firstname,
                    lastname: response.data.lastname,
                    email: '',
                    assistance_required: true,
                    updated_at: undefined,
                    created_at: undefined,
                };
                patient.set(newCurrentPatient);
            }

            // Purge swap arrays
            setSwappedDrillDownCharts([]);
            setSwappedLiveCharts([]);

        }

        // Validate UserSession and PatientData before calling the Endpoint
        if(newPatientTaxCode?.match(taxCodeRegex) && session?.data?.userData?.id){
            Axios(session, 'get', `/api/patient/GetPatientFromTaxCode/${newPatientTaxCode}`, onSuccess, (response:any) => {console.log("Error:", response)});
        }

    }


    /** @section --SELECT MENU FUNCTIONS-- */

    /** onClickOutsideAnalysisMenu event
     * 
     * @brief   If the AnalysisMenu is open, any click outside of the Component will collapse the Menu.
     * 
     */
    useEffect(() => {
        function handleClickOutside(event:any) {

            // Close AnalysisMenu on click outside
            if (customSelectRef.current && !customSelectRef.current.contains(event.target)) {
                setAnalysisMenuOpen(false);
            }

        }
    
        // Bind / unbind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [customSelectRef]);


    /** @section --FETCH BARCHART FUNCTIONS-- */

    /* Debug Bars */
    useEffect(() => {

        // console.log(barCharts);
        // console.log(barCharts.length == selectedOptions?.length)
        // console.log(swappedDrillDownCharts)

    }, [barCharts]);

    /** Download and Plot RectangleBars on AnalysisMenu selection / DatePicker change */
    useEffect(() => {

        // For each selected option, fetch data if it's not yet fetched or dates have changed
        selectedOptions?.forEach((option) => {
            if (!barCharts.some((chart) => chart.type === option.label) || startDate !== prevStartDateRef.current || endDate !== prevEndDateRef.current) {
                fetchBarChart(option);
            }
        });

        // Call handleRemoveBarChart to remove unselected barCharts
        handleRemoveBarChart();

        // Update previous dates
        prevStartDateRef.current = startDate;
        prevEndDateRef.current = endDate;
      
    }, [selectedOptions, startDate, endDate]);

    // Function to calculate a new brush index based on data length and existing index
    const calculateNewBrushIndex = (dataLength: number, existingIndex: number[]) => {
        if (dataLength === 0) return [0, 0]; // Handle no data case

        // Adjust this logic based on how you want to set the brush range
        if (dataLength <= 5) {
            // If data is less than or equal to 5, show all data
            return [0, dataLength - 1];
        } else {
            // If data is more than 5, you can set a default range or use the existing index
            return existingIndex || [dataLength - 6, dataLength - 1];
        }
    };
      
    /** Fetch RectangleBars from the API */
    const fetchBarChart = async (option:any) => {

        // Create a new cancel token for the request
        const cancelTokenSource = axios.CancelToken.source();
        cancelTokenBarchartSourceRef.current?.push(cancelTokenSource);

        // Add fetched barChart to the barCharts array
        const onSuccess = (option: any) => (response:AxiosResponse) => {
            
            // Block data signed with a different TC than the selected patient
            if(response.data.signature && response.data.signature !== patient?.data?.tax_code) return;

            // Add brushIndex for Recharts in the metadata
            // Check if there's an existing brush index for this chart type
            const existingIndex = brushIndices[option.label];
            if (existingIndex) {
            response.data.metadata.prevBrushIndex = existingIndex;
            } else {
            response.data.metadata.prevBrushIndex = [0, 0]; // Default value
            }

            // Calculate new brush index based on the fetched data
            const newDataLength = response.data.payload.length;
            const newBrushIndex = calculateNewBrushIndex(newDataLength, existingIndex);

            // Update metadata with the new brush index
            response.data.metadata.prevBrushIndex = newBrushIndex;
                handleAddBarChart(option.label, response.data.payload, response.data.metadata)
            }

        // Add an empty barChart to the barCharts array
        const onError = (option: any) => (error:AxiosError) => {

            // Check if the request was cancelled to avoid state updates on an unmounted component
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                handleAddBarChart(option.label, [], []);
            }

        }

        // If is not Live mode, fetch regular barCharts
        if(!isLiveToggled){

            // Validate UserSession and PatientData before calling the Endpoint
            if ((patient?.data?.tax_code && patient?.data?.tax_code.match(taxCodeRegex) && session?.data?.userData?.id)) {

                let labelString;
                option.label === "BPM"  ? labelString = option.label : labelString = option.label.toLowerCase();

                axios.get(`/api/analysis/GetRectangleBarsAnalysis/${patient?.data?.tax_code}/${labelString}/${session?.data?.userData?.id}/${startDate.toISOString()}/${endDate.toISOString()}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                cancelToken: cancelTokenSource.token
                })
                .then(response => {

                    // Redirect to onSuccess routine and clear the token
                    onSuccess(option)(response)
                    const index = cancelTokenBarchartSourceRef.current?.indexOf(cancelTokenSource);
                    if (index !== undefined && index > -1) cancelTokenBarchartSourceRef.current?.splice(index, 1);

                })
                .catch(error => {

                    // Redirect to onError routine and clear the token
                    onError(option)(error)
                    const index = cancelTokenBarchartSourceRef.current?.indexOf(cancelTokenSource);
                    if (index !== undefined && index > -1) cancelTokenBarchartSourceRef.current?.splice(index, 1);

                });
            }
            
        }
    }

    /** Cancel fetchBarChart request when the component unmounts */
    useEffect(() => {

        return () => {
            cancelTokenBarchartSourceRef.current?.forEach(source => source.cancel('Patient changed - fetchBarChart cancelled'));
            cancelTokenBarchartSourceRef.current = []; 
        };

    }, []);
    /** Cancel fetchBarChart request onPatientChange */
    useEffect(() => {

        cancelTokenBarchartSourceRef.current?.forEach(source => source.cancel('Patient changed - fetchBarChart cancelled'));
        cancelTokenBarchartSourceRef.current = []; 

    }, [patient?.data?.tax_code]);

    /** Add or Replace RectangleBars into the BarChart array */
    function handleAddBarChart(type:string, data: any, metadata:any) {

        setBarCharts(prevBarCharts => {

            // Get previous index, if any
            const existingIndex = prevBarCharts.findIndex(barChart => barChart.type === type);
    
            // If this type of BarChart doesn't exist yet, append it
            if (existingIndex === -1) {
                return [...prevBarCharts, {index: prevBarCharts.length, type: type,  data: data, metadata: metadata}]
                    .sort((a, b) => a.type.toLowerCase().localeCompare(b.type.toLowerCase()))
                    .map((chart, index) => ({...chart, index}));

            }else{

                // If this type of BarChart does exist, replace it
                const newBarCharts = [...prevBarCharts];
                newBarCharts[existingIndex] = {index: existingIndex, type: type,  data: data, metadata: metadata};
                return newBarCharts;

            }
        });    
    }


    /** @section --EDIT BARCHART FUNCTIONS-- */

    /** Remove a BarChart by deselecting it from the ListView
     * 
     *  @brief  Closes the BarChart with the X button placed inside each chart's div
     *          and clears the SwappedArrays of reference to the Closed BarChart.
     * 
     */
    const handleCloseBarChart = useCallback((chartType: string) => {

        if (selectedOptions) {
            
            // Create a new array without the selected index
            const newSelectedOptions = selectedOptions.filter((option) => option.label !== chartType);

            // Update the selected options
            setSelectedOptions(newSelectedOptions);

            // Update the setswappedDrillDownCharts to remove the selected chart
            setSwappedDrillDownCharts(swappedBarCharts => swappedBarCharts.filter((chart) => chart.type !== chartType));

            // Update the setswappedLiveCharts to remove the selected chart
            setSwappedLiveCharts(swappedBarCharts => swappedBarCharts.filter((chart) => chart.type !== chartType));

        }

    }, [selectedOptions])

    /** Remove unselected BarCharts
     * 
     *  @brief  Given the AnalysisMenu selection, removes any currently plotted
     *          BarChart that is not present in the selection.
     * 
     */
    function handleRemoveBarChart() {

        // Remove unselected barCharts
        setBarCharts(prevBarCharts =>
            prevBarCharts.filter(barChart => 
                selectedOptions?.some(option => option.label === barChart.type)
        ).map((barChart, index) => ({...barChart, index})));

        // Update swapped registers as well
        setSwappedDrillDownCharts(prevBarCharts =>
            prevBarCharts.filter(barChart => 
                selectedOptions?.some(option => option.label === barChart.type)
        ).map((barChart, index) => ({...barChart, index})));

        setSwappedLiveCharts(prevBarCharts =>
            prevBarCharts.filter(barChart => 
                selectedOptions?.some(option => option.label === barChart.type)
        ).map((barChart, index) => ({...barChart, index})));

    }


    /** @section --ORDER BARCHART FUNCTIONS-- */

    /** Move the chart up or down in the BarCharts view */
    const handleReorderBarChart = useCallback((type: string, direction: boolean) => {

        // Copy the current barCharts state
        let newCharts = [...barCharts];

        // Find the index of the chart matching the specified type
        const index = newCharts.findIndex(chart => chart.type === type)

        /// Early return on chart not found
        if (index === -1) return;

        // Convert direction to swap index
        const swapIndex = direction ? index - 1 : index + 1

        /// Check that the swap index is within the array bounds, eventually early return
        if (swapIndex < 0 || swapIndex >= newCharts.length) {
            return; 
        }

        // Swap the elements
        [newCharts[index], newCharts[swapIndex]] = [newCharts[swapIndex], newCharts[index]];

        // Set the new state
        setBarCharts(newCharts);
        
    }, [barCharts])





    /** @section --DRILLDOWN BARCHART FUNCTIONS-- */

    // Utility function to calculate new brush index
    const calculateNewBrushIndexShort = (dataLength: number) => {
        // Logic to calculate new brush index based on data length
        // Example: showing the last 5 data points
        return dataLength > 5 ? [dataLength - 6, dataLength - 1] : [0, dataLength - 1];
    };

    /** Fetch candle DrillDown / Live data 
     * 
     *  @brief                      This function allows displaying DrillDownChart or LiveChart in the place of a default BarChart
     *                              maintaining the chart position in the ChartView.
     * 
     *  @description                Downloads DrillDown data from the API for the selected BarChart, then proceeds
     *                              to swap the selected BarChart array with the downloaded DrillDown array matching by ChartType.
     *                              The default chart is then saved in a SwappedArray of choice (DrillDown or Live) and can be easily
     *                              restored. Infact, function is used as well for displaying LiveCharts, thus accepts a custom swapperSetter
     *                              as parameter to select the target SwappedArray.
     * 
     * @param startRectangleIndex   String representing the starting index for downloading the data in ISO format.
     * @param endRectangleIndex     String representing the ending index for downloading the data in ISO format.
     * @param measurementType       String representing the ChartType [DIA, BPM, ..].
     * @param swapperSetter         Callback Function to set the desired SwappedArray. Must be a useState hook.
     * @param isLive                Boolean to set / unset the isLive flag in the API returned payload. This flag is later used for conditional rendering. [default: false]
     * 
     */
    const fetchDrillDown = useCallback( async (startRectangleIndex: string, endRectangleIndex: string, measurementType: string, swapperSetter: Function, isLive: boolean = false) => {

        console.log("Fetching drillDown..", startRectangleIndex, endRectangleIndex, measurementType)

        // Select query mode
        let queryMode = isLive ? "Live" : "";

        // Swap the BarChart with the DrillDownChart
        const onSuccess = (response:any) => {

            // Get index from previous BarChart
            let matchBarChart = barCharts.find(barChart => barChart.type.toUpperCase() === response.data.type.toUpperCase())

            // Verify that MatchBarChart exists
            if(matchBarChart && matchBarChart !== undefined) {

                // Save the original BarChart to the swapped array
                swapperSetter((prevState:any) => [...prevState, matchBarChart as { index: number, type: string, data:any, metadata:any }]);

                // Update the state
                setBarCharts((prevBarCharts) => {
                
                    // Make a copy of the BarCharts array
                    let updatedBarCharts = [...prevBarCharts];

                    // Find the index of the BarChart to update
                    let updateIndex = updatedBarCharts.findIndex(barChart => barChart.type === matchBarChart?.type);

                    // Update the BarChart in the copy of the array
                    updatedBarCharts[updateIndex] = {
                        ...updatedBarCharts[updateIndex],
                        data: response.data.data,
                        metadata: response.data.metadata
                    };

                    // Calculate the new brush index based on the new data length
                    const newBrushIndex = calculateNewBrushIndexShort(response.data.data.length);

                    // Update the brush index in the metadata
                    updatedBarCharts[updateIndex].metadata.prevBrushIndex = newBrushIndex;

                    // Return the updated BarCharts
                    return updatedBarCharts;

                });

            } else {
                console.log("matchBarChart is undefined or null");
            }

        }

        // Log error
        const onError = (response:any) => {
            console.log(response);
        }

        // Validate UserSession and PatientData before calling the Endpoint
        if(session?.data?.userData?.id && patient?.data?.tax_code && patient?.data?.tax_code.match(taxCodeRegex)){

            let labelString;
            measurementType === "BPM"  ? labelString = measurementType : labelString = measurementType.toLowerCase();
            Axios(session, 'get', `/api/analysis/GetLiveAnalysis/${patient?.data?.tax_code}/${labelString}/${session?.data?.userData?.id}/${startRectangleIndex}/${endRectangleIndex}/${queryMode}`, onSuccess, onError);

        }

    }, [barCharts, patient?.data?.tax_code, session, taxCodeRegex])

    /** Restore a DrillDownChart to the BarChart format 
     * 
     * @brief   Following the same principle of fetchDrillDown, restored a previously swapped DrillDownChart
     *          or LiveChart to its BarChart view and clears the SwappedArray of choice.
     * 
     */
    const handleRestoreDrillDownChart = useCallback(async (chartType: string, swappedArray: any[], swappedSetter: Function) => {
        const chartToRestore = swappedArray.find(chart => chart.type === chartType);
    
        if (chartToRestore) {
            // Switch back to BarCharts
            setBarCharts(prevBarCharts => {
                const newBarCharts = [...prevBarCharts];
                const chartIndex = newBarCharts.findIndex(chart => chart.type === chartType);          
    
                // If there is a type match, replace the chart
                if (chartIndex !== -1) {
                    // Calculate the new brush index
                    const newBrushIndex = calculateNewBrushIndexShort(chartToRestore.data.length);
    
                    // Update the chart with new brush index
                    newBarCharts[chartIndex] = {
                        ...chartToRestore,
                        metadata: {
                            ...chartToRestore.metadata,
                            prevBrushIndex: newBrushIndex
                        }
                    };
                }
    
                return newBarCharts;
            });
    
            // Clear the Chart from the SwappedArray
            swappedSetter((prevSwappedBarCharts:any) => 
                prevSwappedBarCharts.filter((chart:any) => chart.type !== chartType)
            );
        }
    }, []);





    /** @section --LIVE BARCHART FUNCTIONS-- */

    /**
     *     
     * @warning     For a production environment, the current approach is not fit as it fetches an increasingly wide
     *              range of data. For a more live-oriented approach it is best suited to save the startFetchingTime
     *              and, after each polling, append the new data to the existing one instead of re-fetching for already
     *              present data.
     * 
     */

    /** Toggle LiveData mode */
    const handleLiveToggle = () => {

        /**
         *  @warning    Besides the demo, today's date and today's date + 6 seconds goes here
         *              instead of dummy dates.
         */
        // Set the formattedStartDate to 05/06/2023, 10:16:16, where data begins (demo)
        let liveStartDate = new Date('2023-10-18T23:36:38');
        liveStartDate = new Date(liveStartDate.getTime() - (liveStartDate.getTimezoneOffset() * 60 * 1000));
        let formattedStartDate = liveStartDate.toISOString();

        // Set the formattedEndDate to 6 seconds more
        let liveEndDate = new Date(liveStartDate.getTime());
        liveEndDate.setSeconds(liveEndDate.getSeconds() + 6);
        let formattedEndDate = liveEndDate.toISOString();

        // Start fetching the data
        fetchLiveData(formattedStartDate, formattedEndDate);
        setIsLiveToggled(!isLiveToggled);
    };

    /** Fetch Live data */
    const fetchLiveData = async (fStartDate: string, fEndDate: string) => {

        // Increment EndDate by 6 seconds initially
        let liveEndDate = new Date(fEndDate);
        let deltaTime = 0;
    
        const fetchData = async () => {

            // Loop through current BarCharts
            for (const barChart of barCharts) {

                // Fetch custom DrillDown (Live) for each
                await fetchDrillDown(
                    fStartDate,
                    liveEndDate.toISOString(),
                    barChart.type,
                    setSwappedLiveCharts,
                    true
                );
            }
    
            // Increment deltaTimeSeconds and liveEndDate
            deltaTime += 24;
            liveEndDate.setHours(liveEndDate.getHours() + deltaTime);
        };
    
        if (!isLiveToggled) {
            
            // Execute fetchData immediately
            await fetchData();

            // Chart fading effect
            setIsChartOpacityToggled(true)
            setTimeout(() => {setIsChartOpacityToggled(false)}, 1500)
    
            // Then execute fetchData every 6 seconds
            liveIntervalID.current = setInterval(() => {
                fetchData();
            }, 6000);

        } else {

            // Chart Fading effect
            setIsChartOpacityToggled(true)

            // Clear LiveFetch interval
            clearInterval(liveIntervalID.current);

            // Animate Fading effect and restore charts
            setTimeout(() => {

                // Chart fading effect
                setIsChartOpacityToggled(false)

                // Restore the charts
                let p = Promise.resolve();
                for (const swappedChart of swappedLiveCharts) {
                    p = p.then(() => handleRestoreDrillDownChart(swappedChart.type, swappedLiveCharts, setSwappedLiveCharts));
                }

            }, 1500);

        }
    };
    
    /** Clear interval on unmount or when isLiveToggled changes */
    useEffect(() => {
        return () => {
            if (liveIntervalID.current) {
                clearInterval(liveIntervalID.current);
            }
        };
    }, [isLiveToggled]);




    /** TEMPROOM */

    /** @section --TEMPROOM HANDLING FUNCTIONS-- */

    /** Delete the current TempRoom */
    function deleteTempRoom() {
        if (session?.data?.userData?.id) {

            // For a production environment is a best practice to return a response from the API
            const onSuccess = (response: any) => {
            }

            // Call the Endpoint
            const idDoctor = session?.data?.userData?.id;
            Axios(session, 'post', `/api/otr/close/${idDoctor}`, onSuccess, () => true);

            console.log("@deleteTemproom: Room Deleted")

            // Set a DummyPatient to remove the connection with the deleted TempRoom
            if(patient?.set){
                
                const newCurrentPatient: PatientModel = {
                    id: 9999999999,
                    tax_code: '',
                    date_of_birth: '',
                    fullname: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    assistance_required: true,
                    updated_at: undefined,
                    created_at: undefined,
                };
                patient.set(newCurrentPatient);
            }
        }
    }




    /** SYNC */

    /** @section --SYNC HANDLING FUNCTIONS-- */
    
    useEffect(() => {
        if (socket && socket.readyState === WebSocket.OPEN && isSync && isSyncEnabled && barCharts.length > 0) {
            console.log("Sending ChartState to Patient..")
            setIsSyncConnectionClosed(false)
            const chartState = { 
                type: "chart-sync", 
                data: barCharts,
                name: patient?.data?.tax_code,
                sync: true
            };
            socket?.send(JSON.stringify(chartState));
        }else if (socket && socket.readyState === WebSocket.OPEN && !isSync && barCharts.length > 0 && !isSyncConnectionClosed){
            const chartState = { 
                type: "chart-sync", 
                data: barCharts,
                name: patient?.data?.tax_code,
                sync: false
            };
            socket?.send(JSON.stringify(chartState));
            setIsSyncConnectionClosed(true)
        }
    }, [barCharts, isSync])




    /** CALL HANDLING */

    /** @section --CALL HANDLING FUNCTIONS-- */

    /** Start a call 
     * 
     * @brief       After setting the SelectedPatient with the PatientTaxCode, initializes MediaStream for Local / Remote Webcam connection
     *              and adds them to the current RTCPeerConnection. The GEST STUN / TURN server is configured [stun.gest.cloud] for the
     *              connection and the Offer is sent to the Peer.
     * 
     * @warning     For a production environment it is better using Dynamic Credentials with Time-to-Live (TTL) instead of hardcoding
     *              the credential for the STUN / TURN Sever.
     * 
     * @note        Anytime the Peer accepts the Offer, the RemoteTrack will immediately reproduce the stream.
     * 
     */ 
    async function call() {

        // Call config block (Ale)
        callSnackIndexRef.current = snackbar?.data.length;
        audioRef.current.play();
        // console.log(audioRef.current.duration*1000)
        document.title = document.title + ' - Calling';
        audioIntervalRef.current = setInterval(() => {
            audioRef.current.currentTime = 0;
        }, Math.round(audioRef.current.duration*1000) );

        // Disable call button
        setIsCallButtonEnabled(false);
        setIsCalling(true);

        // Connect to Peer
        connectedPeer.current = patient?.data?.tax_code;

        // Detect if a Guest is connected
        if(!taxCodeRegex.test(connectedPeer.current) && connectedPeer.current && connectedPeer.current !== undefined){
            setIsGuestConnected(true);
        }
        
        // Initiate Call if connected to a Peer
        if(connectedPeer.current){
            
            try{
                // await navigator.mediaDevices.getUserMedia({
                //     video: { width: { min: 640, ideal: 1280 }, height: { min: 480, ideal: 720 } },
                //     audio: true
                // }).then((mediaStream : MediaStream) => {

                //     // Displaying local video stream on the page 
                //     stream.current = mediaStream;
                //     localVideoRef.current.srcObject = mediaStream;

                // Getting user media
                const mediaStream = await navigator.mediaDevices.getUserMedia({
                    video: true,
                    audio: true
                });

                // Displaying local video stream on the page
                stream.current = mediaStream;
                localVideoRef.current.srcObject = mediaStream;

                // Using GEST STUN/TURN Server to configure the WebRTC connection
                const configuration = {
                    iceServers: [
                        {
                            urls: 'stun:stun.gest.cloud:3478'
                        },
                        {
                            urls: 'turns:stun.gest.cloud:5349',
                            username: 'gest.cloud.turn',
                            credential: 'xMsa7gTPC4atWnd'
                        }
                    ]
                };
                medicConnection.current = new RTCPeerConnection(configuration);
        
                // Add MediaStream to the active connection to share it with the Peer
                medicConnection.current.addStream(mediaStream);
    
                // When a remote user also adds stream to the peer connection, we display it 
                medicConnection.current.ontrack = (event:any) => {
                    if (event?.streams[0]) {
                        remoteVideoRef.current.srcObject = event.streams[0];
                    }
                };
                
        
                // //Create an Offer [DEPRECATED]
                // medicConnection.current.createOffer(function (offer:any) {
                //     send({
                //         type: "offer",
                //         offer: offer,
                //         fullname: session?.data?.userData?.firstname + " " + session?.data?.userData?.lastname
                //     });

                //     medicConnection.current.setLocalDescription(offer);

                // }, function (error:any) {
                    
                //     if(snackbar){     
                //         snackbar.set({message: 'Errore nell\'invio della chiamata.', severity: 'error'});
                //     }

                // });       
                
                
                // Create an offer
                const offer = await medicConnection.current.createOffer();
                await medicConnection.current.setLocalDescription(offer);

                // Sending offer through the signaling channel
                send({
                    type: "offer",
                    offer: offer,
                    fullname: session?.data?.userData?.firstname + " " + session?.data?.userData?.lastname
                });
                
    
                
                // Add queued candidates to the connection
                candidateQueue.forEach(function (candidate:any) {
                    medicConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
                });

                
                // Setup Ice Handling 
                medicConnection.current.onicecandidate = function (event:any) {
                    if (event.candidate) {
                        send({
                            type: "candidate",
                            candidate: event.candidate
                        });
                    }
                };

             
            }catch (error) {
                // Error handling
                if (snackbar) {
                    snackbar.set({ message: `Errore nell'invio della chiamata: ${error}`, severity: 'error' });
                }
                console.error("Error in call function:", error);
            }
        }
        
        // Display call snackbar
        if(connectedPeer.current !== undefined){
            snackbar?.set({message: `Chiamata in corso ${patient?.data?.firstname} ${patient?.data?.lastname}`, severity: 'success', persistent: true, hideCloseTimes: true});
        }

    }

    /** HangUp the current call 
     * 
     * @brief   Stops the WebCam stream and removes the VideoReferences, then proceeds to remove the TempRoom in case the Peer is 
     *          a GuestPeer and sending a Leave event to the Signaling Server. Ultimately closes the current PeerConnection and
     *          sets ConnectedPeer to null to avoid unwanted calls to the Signaling Server when no Peer is actually connected.
     * 
     */
    function hangUp(){

        // Clear the queue
        candidateQueue = [];

        snackbar?.hide(callSnackIndexRef.current);

        // Disable CallSound
        audioRef.current.pause();
        document.title = document.title.split(' - ')[0];
        audioRef.current.currentTime = 0;
        clearInterval(audioIntervalRef.current);

        // Enable call button
        setIsCallButtonEnabled(true);
        setIsCalling(false);

        // Stop webcam stream
        if(stream?.current){
            stream.current.getTracks().forEach((mst:MediaStreamTrack) => mst.stop())
        }
        
        // Close video refs
        if(localVideoRef.current)
            localVideoRef.current.srcObject = null;
        if(remoteVideoRef.current)
            remoteVideoRef.current.srcObject = null;

        // Delete the TempRoom if hanging up when the Guest is connected
        if(isGuestConnected){
            // Temp User is disconnected
            console.log("hangUp@Destroying TempRoom..")
            deleteTempRoom();
            setIsGuestConnected(false);
        }

        // Send a leave-event
        send({
            type: "leave"
        });

        // Close current connection
        if(medicConnection.current){
            medicConnection.current.close();
            medicConnection.current.onicecandidate = null;
            medicConnection.current.ontrack = null;
        }

        // Reset connectedPeer to avoid unwanted notification routing
        connectedPeer.current = null;

        // Clear SyncState
        setIsSync(false);
    }




    /** MISC */

    /** @section --GRAPHIC OPTIONS-- */

    /** Toggle call container's FullscreenMode (double-click) */
    function toggleFullscreen(){
        setIsFullScreen(!isFullScreen);
    }




    /** --CONDITIONAL RENDERING-- */

    return (
        <Fade in={true}>
            <div id="home">
                <main>
                    <Card class={`${isFullScreen ? "fullscreenMedic conference" : "conference"}`} onDoubleClick={() => toggleFullscreen()}>
                        <div className="conferenceBox">
                            <div className='remoteVideoContainer'>
                                <video className="remoteVideo" ref={remoteVideoRef} autoPlay>
                                </video>
                            </div>
                            {isCalling && 
                                <video className="localVideo" ref={localVideoRef} autoPlay muted>
                                </video>
                            }
                            <div className='remoteName'>
                                <h5>{patient?.data?.fullname} {patient?.data?.tax_code ? `(${patient?.data?.tax_code})` : '' }</h5>
                            </div>
                            <div className={isSyncEnabled ? isSync ? 'syncContainerActive' : 'syncContainer' : 'syncContainerDisabled'} onClick={() => {barCharts.length > 0 && setIsSync(!isSync)}}>
                                <SyncIcon />
                            </div>
                            

                            <div className='commands'>
                                { !isCalling ?
                                    <>
                                        {
                                        <button className='phone call' onClick={call} disabled={isCalling || !patient?.data?.id || !isCallButtonEnabled || patient?.data?.id === 9999999999}>
                                            <CallIcon />
                                        </button>
                                        }
                                    </>
                                    :
                                    <>
                                    <button className='phone hangUp' onClick={hangUp} disabled={!isCalling || !patient?.data?.id}>
                                        <CallEndIcon />
                                    </button>
                                    <div className='volumeBox'>
                                        { showVolumeSlider &&
                                            <Slider
                                                aria-label="Volume"
                                                orientation="vertical"
                                                // getAriaValueText={volume}
                                                defaultValue={66}
                                                onChange={(event: Event, newValue: number | number[]) => {
                                                    if(typeof newValue === 'number'){
                                                        
                                                        remoteVideoRef.current.volume = newValue/100;
                                                        setVolume(newValue/100);
                                                    }
                                                }}
                                            />
                                        }
                                        <button className='phone volume' onClick={() => {
                                            setShowVolumeSlider(!showVolumeSlider);
                                        }}>
                                            {
                                                volumeIcon
                                            }
                                            {/* <CallEndIcon /> */}
                                        </button>
                                    </div>
                                    </>
                                }
                                
                            </div>
                        </div>
                    </Card>

                    <Card title="Parametri Vitali" class="vitals">

                        <div className="measures"   style={{overflowX:'hidden', display: patient?.data?.fullname === "Consulto Esterno" ? 'none' : 'block' }}>

                            <div className="measuresList" style={{marginRight:'0.35em', marginLeft:'0.60em', display: patient?.data?.tax_code ? '' : 'none'}}>

                                <div className='commandMenu' /* flex and justifyContent space-between to push groups of divs to left */>

                                    <div style={{display: 'flex'}} /* Leftmost group */>

                                        <div className="toggleContainer">
                                            <h5 style={{margin:'0 1.0em 0 0.5em', fontSize: '0.8em'}}>Live </h5>
                                            <div className={`toggle-switch ${isLiveToggled ? 'active' : ''}`} onClick={handleLiveToggle}>
                                                <div className="toggle-switch-circle" />
                                            </div>
                                        </div>

                                        <div className="datePickerContainer" style={{opacity: isLiveToggled ? 0.1 : 1, pointerEvents: isLiveToggled ? 'none' : 'auto'}}>
                                            <img alt= "iconDateFrom" src={iconDateFrom} style={{margin:'0.20em 0 0 0.25em', width: '20px', height: '20px'}} />
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date: Date) => setStartDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                maxDate={new Date()}
                                                className='custom-date-picker'
                                                disabled={isLiveToggled}
                                            />
                                        </div>
                                        
                                        <div className="datePickerContainer" style={{opacity: isLiveToggled ? 0.1 : 1, pointerEvents: isLiveToggled ? 'none' : 'auto'}}>
                                            <img alt="iconDateTo" src={iconDateTo} style={{margin:'0.20em 0 0 0.25em', width: '20px', height: '20px'}} />
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date: Date) => setEndDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                minDate={startDate}
                                                maxDate={new Date()}
                                                className='custom-date-picker'
                                                disabled={isLiveToggled}
                                            />
                                        </div>

                                        <div ref={customSelectRef} style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', opacity: isLiveToggled ? 0.1 : 1, pointerEvents: isLiveToggled ? 'none' : 'auto'}}>
            
                                            <Select
                                                options={analysisOptions}
                                                menuIsOpen={analysisMenuOpen}
                                                onMenuOpen={() => setAnalysisMenuOpen(true)}
                                                onMenuClose={() => setAnalysisMenuOpen(false)}
                                                isSearchable={false}
                                                hideSelectedOptions={false}
                                                closeMenuOnSelect={false}
                                                backspaceRemovesValue={false}
                                                isMulti
                                                value={selectedOptions}
                                                onChange={(newValue) => {setSelectedOptions(newValue as { value: string; label: string; }[]);}}
                                                components={{ Option: CustomSelectOption,
                                                            ValueContainer: CustomSelectValueContainer,
                                                            Control: CustomSelectControl,
                                                            ClearIndicator: () => null, 
                                                            IndicatorSeparator: () => null,
                                                            Menu: CustomSelectMenu
                                                }}
                                                styles={{
                                                    control: base => ({
                                                        ...base,
                                                        minHeight: '31px',
                                                        height: '31px',
                                                        backgroundColor: 'var(--gray-800)',
                                                        borderColor: 'lightgray',
                                                        borderRadius: '5px',
                                                        color: 'white',
                                                        paddingLeft: '5px',
                                                        paddingTop:'0.1vw',
                                                        margin: '-0.05em 0 0 0'
                                                    }),
                                                    container: base => ({
                                                        ...base,
                                                        minHeight: '28px',
                                                        height: '28px',
                                                        margin: '0.9em 1em 0 0'
                                                    }),
                                                    indicatorsContainer: base => ({
                                                        ...base,
                                                        minHeight: '28px',
                                                        height: '28px',
                                                    }),
                                                    dropdownIndicator: base => ({
                                                        ...base,
                                                        color: 'white'
                                                    }),
                                                    multiValue: base => ({
                                                        ...base,
                                                        backgroundColor: 'gray'
                                                    }),
                                                    multiValueLabel: base => ({
                                                        ...base,
                                                        color: 'white'
                                                    }),
                                                    menu: base => ({
                                                        ...base,
                                                        backgroundColor: 'var(--gray-800)',
                                                        zIndex: 200
                                                    }),
                                                    menuList: base => ({
                                                        ...base,
                                                        display: 'flex',
                                                        zIndex: 202,
                                                        width: '250px',
                                                        flexDirection: 'column',
                                                        backgroundColor: 'var(--gray-800)',
                                                        boxShadow: '0 0 1px 1px rgba(225, 225, 225, 0.4)',
                                                        borderRadius: '3px',      
                                                    }),
                                                    option: (base:any, state:any) => ({
                                                        ...base,
                                                        backgroundColor: state.isSelected 
                                                        ? state.isFocused ? 'var(--green-2)' : 'var(--gray-700)' 
                                                        : state.isFocused ? 'var(--green-2)' : null,
                                                        padding: '10px',
                                                        color: 'white',
                                                        maxHeight: '35px',
                                                        height: '35px',
                                                        minHeight: '35px',
                                                        ':active': {
                                                            backgroundColor: 'var(--green-1)',
                                                        },
                                                    })
                                                }}
                                            />

                                    </div>

                                </div>

                                <div style={{display: 'flex', justifyContent:'flex-end'}} /* Rightmost group */>

                                    {/* <div style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', maxWidth: '100px', marginTop:'1em', marginLeft:'0.5em', backgroundColor: 'var(--gray-800)'}}>
                                        <button className='plotButton' onClick={fetchBarChart}>PLOT</button>
                                    </div> */}

                                </div>

                            </div>


                             {/* Separator */}
                            {/* <div style={{height:'10px'}}></div> */}


                             {/* Plot Area */}
                            {barCharts.map((chart) => {
                                const chartData = chart.data.map((item:any) => {
                                    let date = new Date(item.measurementId);
                                    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                                    return {
                                        name: date.toLocaleString('it-IT', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            hour: '2-digit', 
                                            minute: '2-digit', 
                                            second: '2-digit',
                                            hour12: false,
                                          }),
                                        max: item.measurementBars.max,
                                        min: item.measurementBars.min,
                                        start: item.measurementId,
                                        end: item.measurementEnd
                                    };
                                });

                                return (
                                    <div key={chart.index} style={{width:'100%', height:'230px', opacity: isChartOpacityToggled ? 0 : 1, transition: 'opacity 0.5s'}}>
                                        <CustomBarChart 
                                            name={chart.type}
                                            data={chartData} 
                                            metadata={chart.metadata}
                                            color1="#8884d8" 
                                            color2="#82ca9d" 
                                            barColor='var(--green-1)'
                                            index={chart.index} 
                                            onClose={(index:any) => handleCloseBarChart(index)}
                                            onRectangleClick={(startIndex:string, endIndex:string, measurementType:string) => fetchDrillDown(startIndex, endIndex, measurementType, setSwappedDrillDownCharts)}
                                            onUndoClick={(chartType: string) => handleRestoreDrillDownChart(chartType, swappedDrillDownCharts, setSwappedDrillDownCharts)}
                                            onReorderClick={(chartType: string, direction: boolean) => handleReorderBarChart(chartType, direction)}
                                            show={true} 
                                            alerts={patients?.data?.find(p => p.tax_code === patient?.data?.tax_code)?.alerts}
                                            updateBrushState={updateBrushState}
                                            />
                                    </div>
                                );
                            })}

                            </div>
                        </div>

                    </Card>

                    <div className="anagraphics">
                        <Card class="anag h-100" title="Scheda Paziente">

                            <div style={{display:'flex', flexDirection:'row', height:'100%'}}>

                                <div className='avatar ms-2 me-4 h-100'>
                                    <Avatar sx={{ width: 150, height: 150, fontSize: 100 }}>
                                        {patient?.data?.firstname ? `${patient.data.firstname[0].toUpperCase()}${patient.data.lastname[0].toUpperCase()}` : ""}
                                    </Avatar>                              
                                    {/* <CustomButton class="editProfile w-100">Edit Profile</CustomButton> */}
                                </div>


                                <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
                                    <div className='anagData'>
                                        <span>Nome e Cognome</span>
                                        <h4>{patient?.data?.fullname}</h4>
                                    </div>
                                    <div className='anagData'>
                                        <span>Data di Nascita</span>
                                        <h4>
                                            {patient?.data?.date_of_birth
                                                ? new Date(patient?.data?.date_of_birth).toLocaleDateString('it-IT', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric'
                                                })                                        
                                                : ''}
                                        </h4>                                        
                                    </div>
                                    <div className='anagData'>
                                        <span>Codice Fiscale</span>
                                        <h4>{patient?.data?.tax_code}</h4>
                                    </div>
                                </div>

                            </div>

                        </Card>
                    </div>


                    {/* <Card class="report" title="Archive">
                        <div className="archive">
                            <div className='header'>
                                <h4>{selectedFolder?.toUpperCase()}</h4>
                            </div>
                            <div className='explorer'>
                                
                                { measures.length > 0 ?
                                    <div className="item folder"
                                        onDoubleClick={() => {
                                            setMeasures([]);
                                            setSelectedFolder('');
                                        }}
                                    >
                                        <DriveFolderUploadIcon />
                                    </div>
                                    :
                                    <></>
                                }
                                { !measures.length && 
                                    folders.map( (v:string, i:number) =>
                                        <div key={i} className="item folder"
                                            onDoubleClick={() => {
                                                loadMeasures(v);
                                                setSelectedFolder(v);
                                            }}
                                        >
                                            <FolderIcon />
                                            <span>{v.toUpperCase()}</span>
                                        </div>
                                    )
                                }

                                { measures.length > 0 ?
                                    measures.map( (v:any, i:number) =>{
                                        
                                        const date = new Datetime(v.start);

                                        return(
                                            <div key={i} className="item file"
                                                onDoubleClick={() => {
                                                    loadMeasure(v);
                                                }}
                                            >
                                                <InsertDriveFileRoundedIcon />
                                                <span>{date.getDateTime()}</span>
                                            </div>
                                        )
                                    })
                                    :
                                    <></>
                                }
                            
                            </div>
                        </div>
                    </Card> */}
                </main>
            </div>
        </Fade>
    );
};
export default React.memo(Home);



// http://localhost:3001/