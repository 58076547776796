import ReactDOM from 'react-dom';
import React from 'react';

type Props = {
    show: boolean,
    onClose: () => void,
    roomNumber: string
}

const OTCPopup: React.FC<Props> = ({ show, onClose, roomNumber }) => {

    const content = (
        <div style={{ ...styles.overlay, opacity: show ? 1 : 0, pointerEvents: show ? 'auto' : 'none' }}>
            <div style={styles.popup}>
                <button style={styles.closeButton} onClick={onClose}>X</button>
                <h2>Link personale per Consulto Esterno</h2>
                <p>app.ihosp.sicilia.it/otr/{roomNumber}</p>

                <button style={styles.copyButton} onClick={() => {
                    if (navigator.clipboard) {
                        navigator.clipboard.writeText(`app.ihosp.sicilia.it/otr/${roomNumber}`);
                    } else {
                        // Fallback for older browsers
                        const textarea = document.createElement("textarea");
                        textarea.textContent = `app.ihosp.sicilia.it/otr/${roomNumber}`;
                        document.body.appendChild(textarea);
                        textarea.select();
                        document.execCommand("copy");
                        document.body.removeChild(textarea);
                    }
                    onClose();
                }}>Copia Link</button>

            </div>
        </div>
    );

    const popupRoot = document.getElementById('root');
    return popupRoot ? ReactDOM.createPortal(content, popupRoot) : null;

};

// Styles
const styles = {
    overlay: {
        position: 'fixed' as const,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 403,
        alignItems: 'center',
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out',
        pointerEvents: 'none' as const,
    },
    popup: {
        backgroundColor: 'white',
        minWidth: '40vw',
        width: '45vw',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
        position: 'relative' as const,
    },
    copyButton: {
        position: 'relative' as const,
        padding: '5px',
        width: '30%',
        margin: 'auto',
        border: 'none',
        borderRadius: '5px',
        background: 'var(--primary)',
        color:'white',
        cursor: 'pointer',
        fontSize: '16px',
    },
    closeButton :{
        position: 'absolute' as const,
        top: '10px',          
        right: '10px',       
        background: 'transparent',
        cursor: 'pointer',
        fontSize: '16px',
        border: 'none'
    }
};


export default OTCPopup;
