
import axios from 'axios';


// (method:string, url:string, params:object, then : (...args: any[]) => void) => {
const Catch = (error, auth) => {
  // console.error(error)
  if(error && error.response && error.response.status){
    
    switch(error.response.status){
      case 401:{
        localStorage.removeItem('token');
        if(auth.data && auth.set){
          
          auth.set(null)
        }
        return false;
        // break;
      }
      // case 451: {
      //   break;
      // }
      default: {
        return true;
        // break;
      }
    }

  }
}  

const Axios = (auth, method, url, then, _catch, data = {}, dataType = 'formData', config = {}, cancelToken = null) => {
  // console.log(auth)

  let token = localStorage.getItem('token');
  if(token){
    config = {
      ...config,
      headers: {
        ...config.header,
        'Authorization': `Bearer ${token}`
      }
    }
  }

  if( dataType === 'formData' ){
    config.headers = {
        ...config.headers,
        // "Content-Type": "multipart/form-data",
        // 'Access-Control-Allow-Origin' : '*',
        // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
  }

  // Check if the cancelToken exists in the config and use it
  if (cancelToken) {
    config.cancelToken = cancelToken;
  }

  const request = axios[method];
  switch(method){
    //   get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
    case 'get':{
      request(url, {...config})
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => {
          if(Catch(error, auth)){
            _catch(error);
          }
        });
      break;
    }
    //   post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
    case 'post':{
      request(url, {...data}, {...config})
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => {
          if(Catch(error, auth)){
            _catch(error);
          }
        });
      break;
    }
    //   put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
    case 'put':{
      request(url, {...data}, {...config})
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => {
          if(Catch(error, auth)){
            _catch(error);
          }
        });
      break;
    }
    //   patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
    case 'patch':{
      request(url, {...data}, {...config})
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => {
          if(Catch(error, auth)){
            _catch(error);
          }
        });
      break;
    }
    //   delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
    case 'delete':{
      request(url, {...config})
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => {
          if(Catch(error, auth)){
            _catch(error);
          }
        });
      break;
    }
    default: {
      console.error('Bad method')
      break;
    }
  }

}

export default Axios;


// export class Axios {
//   constructor(config?: RawAxiosRequestConfig);
//   defaults: AxiosDefaults;
//   interceptors: {
//     request: AxiosInterceptorManager<AxiosRequestConfig>;
//     response: AxiosInterceptorManager<AxiosResponse>;
//   };
//   getUri(config?: RawAxiosRequestConfig): string;
//   request<T = any, R = AxiosResponse<T>, D = any>(config: RawAxiosRequestConfig<D>): Promise<R>;
//   head<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   options<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   postForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   putForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   patchForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
// }
