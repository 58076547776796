import './css/SignUp.css';

import { useState, useContext } from 'react';

import { BrowserRouter as Route, useNavigate } from 'react-router-dom'

import Card from '../components/Card';
import CustomButton from '../components/CustomButton';
import CustomInput from '../components/CustomInput';
import { ctxSession } from '../store';


import importer from '../helpers/importer'
import formDataValidator from '../helpers/formData';

import Axios from '../helpers/axios';

import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import MedicalServicesRoundedIcon from '@mui/icons-material/MedicalServicesRounded';
import SentimentSatisfiedAltRoundedIcon from '@mui/icons-material/SentimentSatisfiedAltRounded';


const SignUp = () => {

    const navigate = useNavigate();
    const session = useContext(ctxSession);

    const [step, setStep] = useState<number>(0);
    const [type, setType] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [repeatPassword, setRepeatPassword] = useState<string | null>(null);


    const set = (n: number, type: string | null = null) => {
        setStep(n)
        if (type) {
            setType(type)
        }
    }

    const back = () => {
        switch (step) {
            case 0: {
                navigate('/Login')
                break;
            }
            default: {
                set(step - 1);
                break;
            }
        }
    }

    const signUp = (e: React.FormEvent<EventTarget>) => {
        e.preventDefault();
        console.log('signUp')
        const formData = formDataValidator(e.target);

        const onSuccess = (response: any) => {
            console.log(`${response}`);
            console.log(`${response.data}`);
        }

        Axios(session, 'post', "/api/auth/signUp", onSuccess, () => true, formData);
    }

    return (
        <Card type="floating" class="shadow" id="test">
            <div className="logo">
                <img className={'mb-3'} src={importer.img.require('iHosp_Logo.png')} alt="" />
                <h4 className='mb-0'>Registrati</h4>
            </div>


            <div className={`my-4 step${step + (type || '')}`} id="steps">

                {step === 0 &&
                    <>
                        <div className='selection mb-2' onClick={() => { set(1, 'doctor') }}>
                            <div className='img'>
                                <MedicalServicesRoundedIcon />
                                {/* <img src={""} alt="" /> */}
                            </div>
                            <div className='text'>
                                <h4>Medico</h4>
                                <p className='text-justify'>Registra un Account di tipo Medico</p>
                            </div>
                            <div className='next'>
                                <ChevronRightRoundedIcon />
                            </div>

                        </div>

                        <div className='selection' onClick={() => { set(1, 'patient') }}>
                            <div className='img'>
                                <SentimentSatisfiedAltRoundedIcon />
                                {/* <img src={""} alt="" /> */}
                            </div>
                            <div className='text'>
                                <h4>Paziente</h4>
                                <p className='text-justify'>Registra un Account di tipo Paziente</p>
                            </div>
                            <div className='next'>
                                <ChevronRightRoundedIcon />
                            </div>

                        </div>
                    </>
                }


                {step === 1 && type === 'doctor' &&
                    <Slide direction="left" in={step === 1 && type === 'doctor' ? true : false}
                    // container={containerRef.current}
                    >
                        <form onSubmit={signUp}>
                            <div id="step_1_doctor">
                                MEDICO
                                <CustomInput name="email" variant="cloud" placeholder="Email" class="mb-2"></CustomInput>
                                <CustomInput name="firstname" variant="cloud" placeholder="Nome" class="mb-2"></CustomInput>
                                <CustomInput name="lastname" variant="cloud" placeholder="Cognome" class="mb-2"></CustomInput>
                                <CustomInput type="date" name="date_of_birth" placeholder="Data di Nascita" variant="cloud" class="mb-2"></CustomInput>
                                <CustomInput name="provincial_order" variant="cloud" placeholder="Ordine Provinciale" class="mb-2"></CustomInput>
                                <CustomInput type="password" name="password" variant="cloud" placeholder="Password" class="mb-2"></CustomInput>
                                <CustomInput type="password" name="repeatPassword" variant="cloud" placeholder="Repeat Password" class="mb-2"></CustomInput>
                                <CustomButton variant="primary" type={"submit"} class="w-100 mb-2">Registrati</CustomButton>
                            </div>
                        </form>
                    </Slide>
                }

                {step === 1 && type === 'patient' &&
                    <Slide direction="left" in={step === 1 && type === 'patient' ? true : false}
                    // container={containerRef.current}
                    >
                        <form onSubmit={signUp}>
                            <div>
                                PAZIENTE
                                <CustomInput name="tax_code" variant="cloud" placeholder="Codice Fiscale" class="mb-2"></CustomInput>
                                <CustomInput name="email" variant="cloud" placeholder="Email" class="mb-2"></CustomInput>
                                <CustomInput name="firstname" variant="cloud" placeholder="Nome" class="mb-2"></CustomInput>
                                <CustomInput name="lastname" variant="cloud" placeholder="Cognome" class="mb-2"></CustomInput>
                                <CustomInput type="date" name="date_of_birth" placeholder="Data di Nascita" variant="cloud" class="mb-2"></CustomInput>
                                <CustomInput type="password" name="password" variant="cloud" placeholder="Password" class="mb-2"></CustomInput>
                                <CustomInput type="password" name="repeatPassword" variant="cloud" placeholder="Repeat Password" class="mb-2"></CustomInput>
                                <CustomButton variant="primary" type={"submit"} class="w-100 mb-2">Registrati</CustomButton>
                            </div>
                        </form>
                    </Slide>
                }

                {step === 2 && type === 'patient' &&
                    <Slide direction="left" in={step === 2 && type === 'patient' ? true : false}
                    // container={containerRef.current}
                    >
                        <div id="step_1_patient">
                            patient
                        </div>
                    </Slide>
                }

            </div>


            <div>
                <CustomButton variant="primary" class="w-100" onClick={back}>
                    Indietro
                </CustomButton>
            </div>


        </Card>
    );
};
export default SignUp;


// http://localhost:3001/