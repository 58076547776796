import React from 'react';
import { useState } from 'react';
import { components } from 'react-select';
import { OptionProps } from 'react-select';


export const CustomSelectOption = (props:any) => {
  const { isSelected, selectOption, data } = props;
  const label = data.label;

  const handleCheckboxClick = (e: React.MouseEvent) => {
    e.preventDefault();
    selectOption(data);
  };


  return (
          <components.Option {...props}>
              <label style={{ margin: '0 0 0 0', display: 'flex', alignItems:'center'}} onClick={handleCheckboxClick} >

                <input type="checkbox" style={{backgroundColor: 'var(--green-2)'}} checked={isSelected} onChange={() => /*setIsChecked(!isChecked)*/ {}} />{" "}
                <span className={`checkbox ${isSelected ? "checkbox--active" : ""}`}></span>
                <h6 style={{margin: '0.1em 0 0 1em', fontSize: '0.8em'}}>{label}</h6>
              
              </label>
          </components.Option>
  );
};