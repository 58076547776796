
import React, { useState, useEffect, useRef, useContext } from 'react';
import { BrowserRouter, useLocation, useParams, useNavigate } from 'react-router-dom';


import { ctxSession, ctxPatient, ctxSnackbar } from '../store';


import Slider from '@mui/material/Slider';

import Card from '../components/Card';


import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';

import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';


import Axios from '../helpers/axios'



// TODO: This module has to be updated as a whole once Home.tsx is finished



const OTRPage = () => {

    // Page register
    const session = useContext(ctxSession);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const navigate = useNavigate();
    

    // Room ID register
    const { roomNumber } = useParams();

    // Load TempRoom or redirect to Login if the room does not exist
    useEffect( () => {
        if(!isLoaded){

            const onSuccess = () => {

                // TempRoom exists
                setIsLoaded(true);  

            }

            const onError = () => {

                // Redirect to login page
                setIsLoaded(false);  
                navigate('/');
                return null;

            }

            Axios(session, 'get', `/api/otr/${roomNumber}`, onSuccess, onError);
        }
    })

    const snackbar = useContext(ctxSnackbar);

    // Video stream register
    const localVideoRef = useRef<any>();
    const remoteVideoRef = useRef<any>();

    // Audio stream register
    const [ volume, setVolume ] = useState<any>(.66);
    const [ volumeIcon, setVolumeIcon ] = useState<any>(<VolumeUpIcon />);
    const [showVolumeSlider, setShowVolumeSlider] = useState<boolean>(false);

    useEffect( () => {
        
        if(!volume){
            setVolumeIcon( <VolumeOffIcon /> );
        }else{
            if(volume >= .5){
                setVolumeIcon( <VolumeUpIcon /> );
                // return <VolumeUpIcon />;
            }else{
                setVolumeIcon( <VolumeDownIcon /> );
                // return <VolumeDownIcon />;
            }
        } 
    }, [volume]);

    // WebRTC connection register
    const patientConnection = useRef<any>();

    // Peers register
    const connectedUser = useRef<any>();
    let connectedPeer = useRef<any>();
    let connectedPeerFullName = useRef<any>();
    let [connectedPeerDisplay, setConnectedPeerDisplay] = useState(false);

    // Answer register
    const receivedOffer = useRef(null);

    // Incoming call register
    const [incomingCall, setIncomingCall] = useState(false);
    const [inCall, setInCall] = useState(false);

    // CallSound register
    const audio = new Audio(require('../../assets/sounds/incomingCall.mp3'));
    const audioRef = useRef<HTMLAudioElement>(audio);
    audioRef.current.volume = 0.66;
    const audioIntervalRef = useRef<any>();

    // Socket register
    const [socket, setSocket] = useState<WebSocket|null>(null);

    // Candidates queue register
    let candidateQueue:any = [];



    /** CALL HANDLING */



    // Sets incomingCall as the Offer is received from the peer
    function eventCallOpen(){
        audioRef.current.play();
        document.title = document.title + ' - In coming Call';
        audioIntervalRef.current = setInterval(() => {
            audioRef.current.currentTime = 0;
            audioRef.current.play();
        }, Math.round(audioRef.current.duration*1000) );
        setIncomingCall(true);
    }
    
    // Unsets incomingCall as one of the peers hang up
    function eventCallClose(){
        audioRef.current.pause();
        document.title = document.title.split(' - ')[0];
        audioRef.current.currentTime = 0;
        clearInterval(audioIntervalRef.current);
        setIncomingCall(false);
    }


    /** CONNECTION HANDLING */
    

    useEffect( () => {
        if(!socket){
            
            // Current PeerUser
            connectedUser.current = {roomNumber};

            // Server socket connection
            const conn = new WebSocket(`wss://${window.location.hostname}:9091`);
    
            // Event onSocketOpen
            conn.onopen = () => {
                console.log("Connected to the Application Server");
            };
            
            // Event onServerMessage
            conn.onmessage = (msg) => {

                // console.log("Got message", msg.data);

                // Check that msg-payload is properly formed
                let data = null;
                try {
                    data = JSON.parse(msg.data);
                    console.log('Incoming Data:', data);
                } catch (e) {
                    console.error(e, msg.data);
                }

                // If msg.data is what exepected, send a LoginRequest
                if (data === "Server Online") {
                    conn.send(JSON.stringify({
                        type: "login",
                        name: connectedUser.current.roomNumber
                    }));
                    console.log(data);

                    // Send GuestLogged signal to server
                    conn.send(JSON.stringify({
                        type: "guest-connected",
                        name: connectedUser.current.roomNumber
                    }))

                    return;
                }

                // Handle Socket events
                switch (data.type) {

                    // Login
                    case "login":
                        handleLogin(data.success);
                        break;

                    // A call (offer) is sent to this PatientPeer
                    case "offer":
                        // Update variables for answering logic
                        receivedOffer.current = data.offer;
                        connectedPeer.current = data.name;
                        connectedPeerFullName.current = data.fullname;

                        // Trigger call open event
                        eventCallOpen();
                        break;

                    // An answer is sent to this PatientPeer
                    case "answer":
                        handleAnswer(data.answer);
                        break;

                    // The MedicPeer sends its ICE candidates
                    case "candidate":
                        handleCandidate(data.candidate);
                        break;

                    // The call is closed
                    case "leave":
                        handleLeave();
                        hangUp();
                        break;

                    default:
                        break;
                }
            };
            
            conn.onerror = function (err) {
                console.log("Got error", err);
            };
    
            setSocket(conn)

        }

    }, []);



     
    // Handle Login result
    const handleLogin = (success:any) => {
        if (success === false) {

            // snackbar.set([...snackbar?.data, {message: 'Sei già connesso!', severity: 'warning', millis: 2000}]);
            alert("La sessione è già aperta in un'altra scheda. Questa pagina si chiuderà automaticamente in 5 secondi.");
            setTimeout(() => {
                window.location.href = 'https://www.google.com';
            }, 5000);

        }
    }

    
    // Handle Answers sent to this PatientPeer
    function handleAnswer(answer:any) {
        audioRef.current.pause();
        document.title = document.title.split(' - ')[0];
        audioRef.current.currentTime = 0;
        
        patientConnection.current.setRemoteDescription(new RTCSessionDescription(answer));
    };

        
    // Handle ICE Candidates sent to this PatientPeer
    function handleCandidate(candidate:any) {
        if(patientConnection.current){
            patientConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
        }else{
            candidateQueue.push(candidate);
        }
    };
    

    // Handles closing the call
    function handleLeave() {
            
        if(stream?.current){
            stream.current.getTracks().forEach((mst:MediaStreamTrack) => mst.stop())
        }

        snackbar?.set({message: 'Il Medico si è disconnesso.', severity: 'error'});
        setConnectedPeerDisplay(false);
        eventCallClose();

    };



    /** UTILITIES */



    // Send messages to the MedicPeer
    function send(message:any) {
        //attach the other peer username to our messages 
        if (connectedPeer.current) {
            message.name = connectedPeer.current;
        }

        // Send message through socket
        socket?.send(JSON.stringify(message));
    };



    /** EVENTS */



    // onLeave Event
    useEffect(() => {

        const handleUnload = (ev:any) => {
            ev.preventDefault();
            hangUp();
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);



    // Answer a call 
    const stream = useRef<MediaStream|null>(null);
    function answer() {
        setInCall(true);

        // Check if an offer has been received before proceeding
        if (receivedOffer.current) {

            // Assign MediaStream and ICEConfiguration to RTCPeerConnection
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then((mediaStream : MediaStream) => {
                    
                    stream.current = mediaStream;
                    localVideoRef.current.srcObject = mediaStream;
                    // localVideoRef.current.volume = 0;

                    setConnectedPeerDisplay(true);

                    // Initiate RTCPeerConnection
                    const configuration = {
                        iceServers: [
                            {
                                urls: 'stun:stun.gest.cloud:3478'
                            },
                            {
                                urls: 'turns:stun.gest.cloud:5349',
                                username: 'gest.cloud.turn',
                                credential: 'xMsa7gTPC4atWnd'
                            }
                        ]
                    };

                    patientConnection.current = new RTCPeerConnection(configuration);

                    // Add queued candidates to the connection
                    candidateQueue.forEach(function (candidate:any) {
                            patientConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
                    });

                    // Exchange ICE Candidates
                    patientConnection.current.onicecandidate = (event: any) => {
                        if (event.candidate) {
                            send({
                                type: "candidate",
                                candidate: event.candidate
                            });
                        }
                    };

                    // Set the received Offer and SessionDescription
                    if (receivedOffer.current !== null) {
                        patientConnection.current.setRemoteDescription(new RTCSessionDescription(receivedOffer.current));
                    }

                    patientConnection.current.onaddstream = (e: any) => {
                        remoteVideoRef.current.srcObject = e.stream;
                    };

                    patientConnection.current.addStream(mediaStream);

                    // Create an Answer to the MedicPeer's offer
                    patientConnection.current.createAnswer((answer: any) => {
                        patientConnection.current.setLocalDescription(answer);
                        send({
                            type: "answer",
                            answer: answer,
                            name: connectedPeer.current
                        });
                    }, (error: any) => {
                        console.error("Error when creating an answer", error);
                    });

                    // Clear the queue
                    candidateQueue = [];

                    // Clear incoming call register
                    eventCallClose();

                })
                .catch(error => {
                    console.error("Error accessing media devices.", error);
                });

    } else {
        alert("No call to answer.");
    }

    }       


    // HangUp a call
    function hangUp(){

        // hangUpAudioRef.current.play();
        // hangUpAudioRef.current.currentTime = 0;

        setInCall(false);
        // Trigger call close event 
        eventCallClose();

        // Close video stream
        if(stream?.current){
            stream.current.getTracks().forEach((mst:MediaStreamTrack) => mst.stop())
        }

        // Close video refs
        if(localVideoRef.current){
            localVideoRef.current.srcObject = null;
        }
        remoteVideoRef.current.srcObject = null;

        setConnectedPeerDisplay(false);

        // Send a leave-event
        send({
            type: "leave"
        });

        // Close connection
        if(patientConnection.current){
            patientConnection.current.close();
            patientConnection.current.onicecandidate = null;
            patientConnection.current.onaddstream = null;
        }

        // Reset connected peer
        connectedPeer.current = null;
        connectedPeerFullName.current = null;

        // Log out of the call center
        socket?.close();

        // Reroute to login screen
        setIsLoaded(false);  
        navigate('/');
        return null;

    }   

    // return (
    //     <div>
    //         <h2>ID Meeting: {roomNumber}</h2>
    //         {/* rest of your component */}
    //     </div>
    // <div style={{color:'white'}} className='remoteName'>
    //     <h5>{roomNumber}</h5>
    // </div>  
    
    // );

    const styles = {
        container: {
            display: 'flex',
            position: 'relative' as const,
            height: '97vh',
            width: '90vw', 
            maxWidth: '90vw',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',    
            background: 'var(--gray-800)',
            borderRadius: '5px',
            padding: '30px',
        },
        remoteVideo: {
            position: 'relative' as const,
            margin: '30px',
            height: '90vh',
            // width: '100vw', 
        },
        localVideo: {
            position: 'absolute' as const,
            top: '30px',
            right: '60px',
            height: '16vh',
            width: '16vw', 
        },
        commands: {
            position: 'absolute' as const,
            transform: 'translate(-50%, 30vh)',
            top: "50%",
            left: "50%",
            display: 'inline-flex',
            alignItems: 'center'
        },
        icons:{
            margin:'0 15px 0 15px',
        }
    }


    return (
        <>
        {
            isLoaded ?
            <Card class="conference">
                
                <div className="conferenceBox" >
                    
                    <div className='remoteVideoContainer' style={styles.container}>
    
                        <video className="remoteVideo" style={styles.remoteVideo} ref={remoteVideoRef} autoPlay playsInline>
                        </video>
    
                        { ( inCall ) &&
                            <video className="localVideo" style={styles.localVideo} ref={localVideoRef} autoPlay muted>
                            </video>
                        } 
    
                    </div>
                        
                </div>

                <div>        
                    <div style={styles.commands} className='commands' >

                            <div className='remoteName'>
                                <h5 style={{color: 'white'}}>{connectedPeerFullName.current ? 'Dott. ' + connectedPeerFullName.current : ''}</h5>
                            </div>

                                { incomingCall ?
                                    <>
                                        <button style={styles.icons} className={`phone call ${incomingCall ? 'pulsing' : ''}`} onClick={answer}>
                                            <CallIcon />
                                        </button>
                                        
                                        <button className='phone hangUp' onClick={hangUp}>
                                            <CallEndIcon />
                                        </button>
                                    </>
                                    :
                                    <>
                                    {
                                        inCall && 
                                        <>
                                        <button style={styles.icons} className='phone hangUp' onClick={hangUp}>
                                            <CallEndIcon />
                                        </button>
                                        <div className='volumeBox'>
                                            { showVolumeSlider &&
                                                <Slider
                                                    aria-label="Volume"
                                                    orientation="vertical"
                                                    // getAriaValueText={volume}
                                                    defaultValue={66}
                                                    onChange={(event: Event, newValue: number | number[]) => {
                                                        if(typeof newValue === 'number'){
                                                            
                                                            remoteVideoRef.current.volume = newValue/100;
                                                            setVolume(newValue/100);
                                                        }
                                                    }}
                                                />
                                            }
                                            <button className='phone volume' onClick={() => {
                                                setShowVolumeSlider(!showVolumeSlider);
                                            }}>
                                                {
                                                    volumeIcon
                                                }
                                                {/* <CallEndIcon /> */}
                                            </button>
                                        </div>
                                        </>
                                    }   
                                    </>
                                }
                                
                                
                                {/* <button onClick={() => {
                                    if(snackbar){
                                        
                                        snackbar.set([...snackbar.data, {message: 'Errore nell\'invio della chiamata.', severity: 'error', millis: 2000}]);
                                    }
                                }}>
                                    test
                                </button> */}
                    </div>
                </div> 
                    
            </Card>
            :
            <div className='loading'></div>
        }

    </>
    );
}


export default React.memo(OTRPage);
