export enum AlarmCode {
    GREEN = 0,
    YELLOW = 1,
    ORANGE = 2,
    RED = 3
}

export type Patient_model = {
    id: number;
    tax_code: string;
    date_of_birth: string;
    firstname: string;
    lastname: string;
    fullname?: string;
    email: string;
    status?: number;
    assistance_required?: boolean;
    care?: boolean;
    alerts?: {
        temp: boolean,
        BPM: boolean,
        spo2: boolean,
        dia: boolean,
        sys: boolean
    },
    color_code?: AlarmCode,
    updated_at?: string;
    created_at?: string;
};