import React from 'react';
import { useState } from 'react';
import { components } from 'react-select';

export const CustomSelectMenu = (props:any) => {
    // console.log(props)

    return (
      <div className='custom-menu'>
        <components.Menu {...props}>{props.children}</components.Menu>
      </div>
    );
  };