import { createContext } from "react";
import { Session } from './contexts/Session';
import { Snackbar } from './contexts/Snackbar';
import { Patient } from './contexts/Patient';
import { Patients } from "./contexts/Patients";

export const ctxSession = createContext<Session | null>(null);

export const ctxPatient = createContext<Patient | null>(null);


// export const ctxSnackbar = createContext<Snackbar | null>(null);
export const ctxSnackbar = createContext<Snackbar | null>(null);

// Context for Patients Requests or other exchange data (online status for example)
export const ctxPatients = createContext<Patients | null>(null);


// export ctxSession;

// export default createContext<ctxSession>({});
// export default {
//     Session
// };