import ReactDOM from 'react-dom';
import React, { useState } from 'react';
import { Medic_model } from '../models/data/Medic_model';

type Props = {
    show: boolean,
    onClose: () => void,
    onSend: (selectedMedicId:any) => void,
    doctors: Medic_model[] | any[]
}

const PopupList: React.FC<Props> = ({ show, onClose, onSend, doctors }) => {

    // Selected Medic register
    const [selectedMedicId, setSelectedMedicId] = useState(null);
    const handleMedicSelection = (id:any, isOnline:any) => {
        if(isOnline){
            setSelectedMedicId(id);
        }
    }

    // Handler for onSend function
    const handleSendClick = () =>{
        if(selectedMedicId !== null){
            onSend(selectedMedicId);
            onClose();
        }
    }

    const content = (

        <div style={{ ...styles.overlay, opacity: show ? 1 : 0, pointerEvents: show ? 'auto' : 'none' }}>
            <div style={styles.popup}>
                <button style={styles.closeButton} onClick={() => {handleMedicSelection(null, false); onClose()}}>X</button>
                <h2 style={{fontSize: '1.3em'}}>Seleziona un Medico Curante Online</h2>

                <div style={{ overflowY: 'scroll', overflowX:'hidden', height: '30vh', maxHeight: '30vh'}}>
                    
                    {doctors.map(entry => (
                        <div key={entry.id_doctor} className="user" onClick={() => handleMedicSelection(entry.id_doctor, entry.isOnline)}>

                            <div style={ entry.id_doctor === selectedMedicId ? styles.listItemSelected : entry.isOnline ? styles.listItem : {...styles.listItem, backgroundColor:'var(--gray-600)', cursor:'default'}}>
                                <h4 style={(entry.id_doctor === selectedMedicId) ? styles.textItem : styles.textItemSelected}>{entry['doctor.firstname']} {entry['doctor.lastname']} {entry.isOnline ? '' : <span style={{fontSize: '0.6em', verticalAlign: 'super'}}>OFFLINE</span>}</h4>
                                <h6 style={entry.id_doctor === selectedMedicId ? styles.textItem : styles.textItemSelected}>{entry.id_doctor}</h6>
                            </div>
     
                        </div>
                    ))}

                </div>

                <div style={{display:'flex', justifyContent:'start'}}>
                    <button style={styles.sendButton} onClick={() => {handleSendClick(); handleMedicSelection(null, false)}}>Invia Richiesta</button>
                </div>

            </div>
        </div>

    );

    const popupRoot = document.getElementById('root');
    return popupRoot ? ReactDOM.createPortal(content, popupRoot) : null;

};

// Styles
const styles = {
    overlay: {
        position: 'fixed' as const,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1000,
        alignItems: 'center',
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out',
        pointerEvents: 'none' as const,
    },
    popup: {
        backgroundColor: 'white',
        minWidth: '40%',
        width: '45%',
        padding: '1.5em',
        borderRadius: '10px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
        position: 'relative' as const,
    },
    sendButton: {
        position: 'relative' as const,
        padding: '0.5vw',
        border: 'none',
        borderRadius: '5px',
        background: 'var(--primary)',
        color:'white',
        cursor: 'pointer',
        fontSize: '0.8em',
        margin: '3em 0'
        },
    closeButton:{
        position: 'absolute' as const,
        top: '10px',          
        right: '10px',       
        background: 'transparent',
        cursor: 'pointer',
        fontSize: '1.3em',
        border: 'none'
    },
    listItem:{
        backgroundColor: '#d3d3d3',
        cursor: 'pointer', 
        padding: '0.8vw',
        borderRadius: '10px',
        margin: '0.8em 0 0.8em 0'
    },
    listItemSelected:{
        background: 'var(--primary)',
        cursor: 'pointer', 
        padding: '0.8',
        borderRadius: '10px',
        margin: '0.8em 0 0.8em 0'
    },
    textItem:{
        color: 'white',
        marginBottom: '0',
        fontSize: '1em'
    },
    textItemSelected:{
        color: 'black',
        marginBottom: '0',
        fontSize: '1em'
    }

};


export default PopupList;
