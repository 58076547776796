import './css/Sidebar.css'

import React, { useEffect, useState, useRef, useMemo, useContext } from "react";

import CustomInput from '../components/CustomInput'

import importer from '../helpers/importer'

import Slide from '@mui/material/Slide';
import Avatar from '@mui/material/Avatar';



import Axios from '../helpers/axios';
import { ctxSession, ctxPatient, ctxPatients } from '../store';
import { PatientModel } from '../models'

import AddLinkIcon from '@mui/icons-material/AddLink';
import Tooltip from '@mui/material/Tooltip';

import OTCPopup from './OTCPopup'

import { AxiosError, AxiosResponse } from 'axios';
import { AlarmCode, Patient_model } from '../models/data/Patient_model';

import { ReactComponent as MenuOpen } from '../../assets/images/icons/menuOpen.svg'
import { ReactComponent as MenuClose } from '../../assets/images/icons/menuClose.svg'
import PatientAlertIcon from '@mui/icons-material/NotificationsActive';;


const Sidebar = (/*props: HeaderProps*/) => {

    const session = useContext(ctxSession);
    const user: any = useMemo(() => {
        if (session?.data?.userData) {
            const { userData } = session.data;

            if (session.data.userData.roles.includes('patient')) {
                let fullname: string = userData.firstname[0].toUpperCase() + userData.firstname.slice(1).toLowerCase()
                    + ' ' + userData.lastname[0].toUpperCase() + userData.lastname.slice(1).toLowerCase()
                return {
                    fullname,
                    username: userData.taxCode
                }

            } else if (session.data.userData.roles.includes('doctor')) {
                let fullname: string = userData.firstname[0].toUpperCase() + userData.firstname.slice(1).toLowerCase()
                    + ' ' + userData.lastname[0].toUpperCase() + userData.lastname.slice(1).toLowerCase()
                return {
                    fullname,
                    username: userData.email
                }

            }
        }

        return {
            fullname: '',
            username: ''
        }
    }, [session?.data?.userData]);



    // RegExs for checks
    const taxCodeRegex = /^([A-Z]{3})([A-Z]{3})(\d{2})([ABCDEHLMPRST])(\d{2})([A-M]\d{3})([A-Z])$/;

    // Sidebar toggle register
    const [isOpened, setIsOpened] = useState<boolean>(false);

    // Siderbar search state
    const [search, setSearch] = useState("");

    // Selected Patient context
    const selectedPatient = useContext(ctxPatient);

    // Patients for this Medic context
    const patientsContext = useContext(ctxPatients);

    // Popup register
    const [showPopup, setShowPopup] = useState(false);
    const [roomNumber, setRoomNumber] = useState('');

    // Patients alerts register
    const alertsIntervalID = useRef<ReturnType<typeof setInterval>>();
    const patientsContextRef = useRef<PatientModel[] | null>(null)
    const priorityAlert = useRef<AlarmCode>(AlarmCode.GREEN)

    // Alert rendering map
    const AlertMapIndicator: { [key in AlarmCode]: string } = {
        [AlarmCode.GREEN]: "",
        [AlarmCode.YELLOW]: "yellow-pulse",
        [AlarmCode.ORANGE]: "orange-pulse",
        [AlarmCode.RED]: "red-pulse"
    };
    
    const AlertMapUser: { [key in AlarmCode]: string } = {
        [AlarmCode.GREEN]: "",
        [AlarmCode.YELLOW]: "yellow-alert",
        [AlarmCode.ORANGE]: "orange-alert",
        [AlarmCode.RED]: "red-alert"
    };


    const stringToColor = (string: string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const stringAvatar = (name: string) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    const toggleMenu = () => {
        setIsOpened(!isOpened);
    }



    /* ONEVENT FUNCTIONS */



    // Set search string
    const searchPatient = (value:string) => {
        setSearch(value)
    }


    // Search patient
    const filterPatients = (v:PatientModel) => {
        return v.tax_code.includes(search.toUpperCase()) || v.email.toLowerCase().includes(search.toLowerCase()) || v.firstname.toLowerCase().includes(search.toLowerCase()) || v.lastname.toLowerCase().includes(search.toLowerCase())
    }


    /** ASSOCIATION FUNCTIONS */

    const handleConfirm = async (patient:any) => {
        const onSuccess = (response:any) => {
            console.log('Association confirmed:', response.data);
            window.location.reload();
        };
    
        const onError = (error:any) => {
            console.error('Error confirming association:', error);
        };
    
        Axios(session, 'post', `/api/doctorPatient/confirmAssociation/${session?.data?.userData?.id}/${patient.tax_code}/accetta`, onSuccess, onError);
    };
    
    const handleReject = async (patient:any) => {
        const onSuccess = (response:any) => {
            console.log('Association rejected:', response.data);
            window.location.reload();
        };
    
        const onError = (error:any) => {
            console.error('Error rejecting association:', error);
        };
    
        Axios(session, 'post', `/api/doctorPatient/confirmAssociation/${session?.data?.userData?.id}/${patient.tax_code}/rifiuta`, onSuccess, onError);
    };
    


    /** PATIENTS */

    /** @section --PATIENTS FETCH FUNCTIONS-- */

    const fetchPatients = async(): Promise<void> => {

        // Don't fetch patients if they are already fetched
        if(patientsContext?.data) return;

        if (session?.data?.userData?.id) {

            const idDoctor = session?.data?.userData?.id;

            const onSuccess = (response: AxiosResponse) => {
                patientsContext?.setData(response.data.data);
                patientsContextRef.current = response.data.data;
                console.log("Patients fetched", response.data.data)
            }

            const onError = (error: AxiosError) => {
                console.error("Error fetching patients:", error)
            }

            Axios(session, 'get', `/api/doctor/${idDoctor}/patient`, onSuccess, onError);
        }

    }

    // Fetch Patients for the given Medic from db onSideMenuOpen
    // useEffect(() => {

    //     fetchPatients();

    // }, [isOpened]);




    /** ALERTS */

    /** @section --ALERTS POLLING FUNCTIONS-- */

    /** Alerts and Care state fetching */
    const fetchAlerts = async (): Promise<void> => {

        // Get patients to avoid working on the context
        const patients = patientsContextRef.current;

        // Get the TaxCode of all the Patients associated with this Medic
        if(patients && patients.length > 0){

            patients.forEach((patient) => {

                // Validate UserSession and PatientData before calling the Endpoint
                if(session?.data?.userData?.id && patient?.tax_code && patient?.tax_code.match(taxCodeRegex) && patientsContext){

                    const onSuccess = (response: AxiosResponse) => {

                        console.log("Alerts fetched successfully:", response);

                        if (response.data.isRecordValid) {

                            // Update the patient in the context with the fetched Alarms
                            patientsContext.setData((prevData: PatientModel[] | null) => {
                                // Check if prevData is not null
                                if (!prevData) return null;
                            
                                // Map over the array and update the alarms for the matching patient
                                let updatedData = prevData.map((p: PatientModel) => {

                                    if (p.id === patient.id) {

                                        // Load the current priority AlarmCode
                                        if(response.data.color_code > priorityAlert.current){
                                            priorityAlert.current = response.data.color_code;
                                        }

                                        // Spread the existing patient properties and only update the alarms
                                        return {
                                            ...p,
                                            care: response.data.care,
                                            alerts: response.data.alerts,
                                            color_code: response.data.color_code,
                                        };

                                    }

                                    return p; // Return the patient as is, if it's not the one being updated
                                });

                                // Sort the updatedData based on the color_code field
                                updatedData.sort((a, b) => {
                                    // Assign default value if color_code is missing
                                    let colorCodeA = a.color_code || 0;
                                    let colorCodeB = b.color_code || 0;
                                    return colorCodeB - colorCodeA;
                                  });

                                return updatedData;

                            });
                            
                        }
                    };
                    const onError = (error: AxiosError) => {
                        console.error("Error fetching alerts for patient:", patient.tax_code, error);
                    };

                    Axios(session, 'get', `/api/analysis/GetAlertsFromTaxCode/${patient.tax_code}/${session.data.userData.id}`, onSuccess, onError);
                }
            })

        }
        
    }

    /** Start fetching Alerts onDocumentLoaded */
    useEffect(() => {

        // Call the function immediately to fetch data on mount
        fetchPatients();

        // Setup an interval to fetch alerts every 6 seconds
        alertsIntervalID.current = setInterval(() => {
            fetchAlerts();
        }, 6000);

        // Clear the timeout on component unmount
        return () => {
            if (alertsIntervalID.current) {
                clearInterval(alertsIntervalID.current);
            }
        };

    }, [session?.data?.userData?.id]);    
    
    /** Cope with React sickness */
    useEffect(() => {

        if(patientsContext?.data){
            // Update contextRef accordingly
            patientsContextRef.current = patientsContext.data;
        }

    }, [patientsContext?.data]);      
    
    // // Debug
    // useEffect(() => {

    //     console.log(patientsContext?.data)

    // }, [patientsContext?.data]);      



    
    /* ONCLICK FUNCTIONS */



    // Create a TempRoom
    function createTempRoom() {
        if (session?.data?.userData?.id) {

            console.log("@createTemproom: Room Created")

            const idDoctor = session?.data?.userData?.id;
            const onSuccess = (response: any) => {

                // Set Popup register
                setRoomNumber(response.data.otc);
                setShowPopup(true);

                /** @warning WORKAROUND: Set a dummy patient (the External Consultation is not supported in this architecture) */
                if(selectedPatient?.set){
                    const guestPatient: PatientModel = {
                        id: 9999999,
                        tax_code: response.data.otc,
                        date_of_birth: '',
                        fullname: 'Consulto Esterno',
                        firstname: 'Consulto',
                        lastname: 'Esterno',
                        email: '',
                        assistance_required: false,
                        updated_at: undefined,
                        created_at: undefined,
                    };
                    selectedPatient.set(guestPatient)
                }

                setIsOpened(false);
                
            }

            Axios(session, 'post', `/api/otr/${idDoctor}`, onSuccess, () => true);

        }
    }

    
    // Set the seletect User as Patient to call
    function setTargetPatient(patient:PatientModel) {
        if(selectedPatient?.set){
            patient.fullname = patient.firstname[0].toUpperCase() + patient.firstname.slice(1).toLowerCase()
            + ' ' + patient.lastname[0].toUpperCase() + patient.lastname.slice(1).toLowerCase()
            selectedPatient.set(patient)
        }
        
        setIsOpened(!isOpened);
    }

    
    // Logout
    const logout = () => {
        
        const onSuccess = () => {
            console.log('Sidebar logout')
            if(session?.set){
                localStorage.removeItem('token');
                session.set({...session.data, token: ''});
            }
        }

        Axios(session, 'get', `/api/auth/logout`, onSuccess, () => true);
    }
    


    return (
        <>
            <Slide direction="right" in={isOpened}
            // container={containerRef.current}
            >
                <div id="menu" className={"menu " + (isOpened ? 'opened' : '')}>
                    <div className="pageTitle">
                        <h2>Lista Pazienti</h2>

                        <CustomInput
                            placeholder="Search"
                            variant="cloud"
                            onChange={searchPatient}
                        />
                    </div>
                    <div className='menuItems'>

                            {patientsContext?.data?.filter((v:PatientModel) => filterPatients(v) ).map(patient => (
                                // TODO: add onclick condition to menuItems itself that deselects any selected user
                                <div key={patient.tax_code} className={"user " + AlertMapUser[patient.color_code as AlarmCode || AlarmCode.GREEN]} onClick={() => patient.status === 1 && setTargetPatient(patient)}>
                                    {/* TODO: alternate style for currently selected patient */}
                                    <h5 style={{ color: 'white' }}>{patient.firstname} {patient.lastname}</h5>
                                    <h6 style={{ color: 'white' }}>{patient.tax_code}</h6>
                                    {patient.status === 2 && (
                                        <div style={{padding:'5px'}}>
                                            <button style={{marginRight:'5px'}} onClick={() => handleConfirm(patient)}>Conferma</button> 
                                            <button onClick={() => handleReject(patient)}>Rifiuta</button>
                                        </div>
                                    )}                                
                                </div>
                            ))}

                    </div>
                    <div className='profile'>
                        <div>
                            <h4>{user.fullname}</h4>
                            <h5>{user.username}</h5>
                        </div>
                        <div className="item" onClick={logout}>
                            <img className="protect" src={importer.ic.require('logoutSidebar.svg')} alt="" />
                        </div>

                    </div>
                </div>
            </Slide>
            <aside id="gestSidebar">
                <div className='topSide'>
                    <div className="menuButton">
                        <div className={"item "}
                            onClick={() => { toggleMenu() }}
                        >
                            {isOpened ? <MenuClose /> : <MenuOpen />}
                        </div>
                    </div>
                    <div className="menu">
                        {/* <div className="item">
                            <img className="protect" src={importer.ic.require('qualifiche.svg')} alt="" />
                        </div>
                        <div className="item">
                            <img className="protect" src={importer.ic.require('walkbydriveby.svg')} alt="" />
                        </div> */}
                    </div>
                </div>

                <div className='bottomSide'>
                    <div className="bottomMenu">
                        {/* <div className="item">
                            <img className="protect" src={importer.ic.require('support.svg')} alt="" />
                        </div> */}

                        <div className={"itemUnclickable " + AlertMapIndicator[priorityAlert.current]} style={{display:"flex", alignItems:"center", justifyContent:"center", borderRadius: '5px'}}>
                            <PatientAlertIcon ></PatientAlertIcon>
                        </div>


                        {/* OTR Button */}
                        <div className="item" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <Tooltip title="Avvia Consulto Esterno">
                                <AddLinkIcon className="protect icon" aria-label="Avvia Consulto Esterno" onClick={createTempRoom}/>
                            </Tooltip>
                            <OTCPopup show={showPopup} onClose={() => setShowPopup(false)} roomNumber={roomNumber} />
                        </div>

                        {!isOpened &&
                            <div className="item" onClick={logout}>
                                <img className="protect" src={importer.ic.require('logoutSidebar.svg')} alt="" />
                            </div>
                        }
                    </div>

                    <div className="account">
                        <Avatar
                            {...stringAvatar(user.fullname || '   ')}
                        />
                    </div>
                </div>
            </aside>

        </>
    );
};
export default React.memo(Sidebar);