import React, { useState, useMemo, useEffect } from 'react';
import './App.css';


import { ctxSession, ctxPatients } from './store';
import { SessionModel, SnackbarModel } from './models';

import Site from './viewmodels/Site'
import Auth from './viewmodels/Auth'

import { Patient_model } from './models/data/Patient_model';
import { Patients } from "./store/contexts/Patients";



function App() {
    
    // Session context
    const [session, setSession] = useState<SessionModel | null>({token: localStorage.getItem('token')})

    // Patients context
    const [patients, setPatients] = useState<Patient_model[] | null>(null);
    const patientsValue = useMemo(() => ({
        data: patients,
        setData: setPatients,
    }), [patients]);

    useEffect( () => {
        if(session?.token == ''){
            setSession(null);
        }
    }, [session])

    
    let main = <></>;
    if(session && (session.token === '' || session.token) ){
        main = <Site />
    }else{
        // return <Site />
        main = <Auth />
    }
    // const main = useMemo( () => {
        
    // }, [session])

    const [snack, setSnack] = useState<SnackbarModel[]>([]);



    return (
        <ctxSession.Provider value={{data: session, set: setSession}} >
    <ctxPatients.Provider value={patientsValue}>
                {main}
            </ctxPatients.Provider>
        </ctxSession.Provider>
    );
}

export default App;
