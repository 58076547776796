import './css/Site.css';
import React, { useState, useMemo, useEffect, useContext } from 'react';

import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

import HomePatient from './HomePatient';
import Home from './Home';
import HomeControl from './HomeControl';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'


import Axios from '../helpers/axios';




import { ctxSession, ctxPatient, ctxSnackbar } from '../store';
import { PatientModel, SessionModel } from '../models';
import { UserData_model } from '../models/data/UserData_model';




import { decodeToken } from 'react-jwt';


import CustomSnackbarContainer from '../components/CustomSnackbarContainer';


const Site = () => {
    const [selectedPatient, setSelectedPatient] = useState<PatientModel | null>(null)


    const session = useContext(ctxSession);
    const snackbar = useContext(ctxSnackbar);

    useEffect( () => {
        if(session && session.data && session.data.token && !session.data?.userData ){

            const onSuccess = (response:any) => {
                if(session && session.data && session.data.token && session.set){
                    const decodedToken : UserData_model | null = decodeToken(session.data.token);
        
                    const sess : SessionModel = {
                        token: session.data.token,
                        userData: decodedToken,
                    }
                    session.set(sess)
                }
            } 
            Axios(session, 'get', `/api/auth/`, onSuccess, () => true);


        }
    }, [] );
    


    const page = useMemo( () => {
        if(session && session.data && session.data.userData){
            
            if( session.data.userData.roles.includes('patient') ){
                return <HomePatient />;

            }else if( session.data.userData.roles.includes('doctor') ){
                return <Home />;
                    ;
            }else if( session.data.userData.roles.includes('control') ){
                return <HomeControl />;
                    ;
            }
            
            
            return <></>
        
        }


    }, [session, session?.data]);

    // const snacks = useMemo( () => {
    //     // console.log(snackbar)
    //     return 
    // }, [snackbar] );

    return (
        <div id={`Site`} className={!session?.data?.userData?.roles.includes('patient') ? 'siteDoctor' : 'sitePatient'}>

            <ctxPatient.Provider value={{data: selectedPatient, set: setSelectedPatient}}>
            <CustomSnackbarContainer>
                {!session?.data?.userData?.roles.includes('patient') && !session?.data?.userData?.roles.includes('control') &&
                    <Sidebar />
                }
                <div id="content">
                    <Navbar />
                    <div className="pages">
                            <Routes>
                                <Route path="/" element={page} /> 
                                {/* <Route path="/" element={<Test />} />  */}
                                { /*
                                <Route path="/employees" element={<Employees />} />
                                <Route path="/employee" element={<Employee />} />

                                <Route path="/test" element={<Test />} /> */}
                                {/* <Route path="/ForgotPassword" element={<ForgotPassword />} /> */}
                                {/* <Route
                                    path="*"
                                    element={<Navigate to="/" />}
                                /> */}
                            </Routes>
                            
                    </div>
                </div>
                
            </CustomSnackbarContainer>
            </ctxPatient.Provider>
        </div >
    );
};
export default Site;