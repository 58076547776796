import './css/LogIn.css';
import React, { useContext } from 'react';
import { BrowserRouter as  Route, useNavigate  } from 'react-router-dom'

import Card from '../components/Card';
import CustomButton from '../components/CustomButton';
import CustomInput from '../components/CustomInput';


import importer from '../helpers/importer'

import Axios from '../helpers/axios';
import { ctxSession } from '../store';

import { UserData_model } from '../models/data/UserData_model';
import { SessionModel } from '../models';

import formDataValidator from '../helpers/formData';


import { decodeToken } from 'react-jwt';


const LogIn = () => {

    const navigate = useNavigate();
    const session = useContext(ctxSession);


    const logIn = (e:React.FormEvent<EventTarget>) => {
        e.preventDefault();

        const formData = formDataValidator(e.target);

        const onSuccess = (response:any) => {
            if( response.data.token ){
               
                localStorage.setItem('token', response.data.token);

                const decodedToken : UserData_model | null = decodeToken(response.data.token);

                if(session && session.set){
                    const sess:SessionModel = {
                        token: response.data.token,
                        userData: decodedToken,
                    };

                    session.set(sess)
                } 
            }else{
                console.log('no Token');
                return;
            }
        }

        Axios(session, 'post', `/api/auth/`, onSuccess, () => true, formData);
    }

    return (
        <Card type="floating" class="shadow">
            <div className="logo">
                <img className={'mb-3'} src={importer.img.require("iHosp_Logo.png")} alt="" />
                <h4 className='mb-0'>Accedi al tuo account</h4>
            </div>

            
            <div className='my-4'>
                <form onSubmit={logIn}>
                    <div className="mb-3">
                        <CustomInput name="username" variant="cloud" img={importer.ic.require('user.svg')} placeholder="Username" class="mb-2"></CustomInput>
                        <CustomInput name="password" variant="cloud" type="password" img={importer.ic.require('password.svg')} placeholder="Password" showable={true}></CustomInput>
                    </div>

                    <div>
                        <CustomButton variant="primary" type={"submit"} class="w-100 mb-2">Accedi</CustomButton>
                        <a className='' onClick={() => {navigate('/ForgotPassword')}}><strong>Forgot password?</strong></a>
                    </div>
                </form>
            </div>
            
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                <p className='mb-2 text-center'>Non sei ancora registrato?</p>
                <CustomButton variant="primary" class="w-100"
                onClick={() => {navigate('/SignUp')}}>Registrati</CustomButton>
            </div>
        </Card>
    );
};
export default React.memo(LogIn);


// http://localhost:3001/